import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from "./firebase/firebase.config.js";

import {
  userDeleteReducer,
  userDetailsReducer,
  userEditReducer,
  userListReducer,
  userRegisterReducer,
  userSignReducer,
  userUpdateProfileReducer,
} from "./reducers/userReducers";
import { articleListReducer } from "./reducers/articleReduces.js";

const initialState = {
  userSignin: {
    userInfo: "",
  },
};

const reducer = combineReducers({
  userSignin: userSignReducer,
  userRegister: userRegisterReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userEdit: userEditReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  articleList: articleListReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
