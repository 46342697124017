import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import SmartguideBtn from "../SmartguideBtn";
import conveyingProductsList from "./conveyingProductsList";

const list = [
  {
    title: "Index Miscellaneous Products",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54510A/",
    img: "/images/miscellaneous-products/0-index-miscellaneous-products.png",
    id: "index-miscellaneous-products",
  },
  {
    title: "Tube Joints & Door Stop",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54520A/",
    img: "/images/miscellaneous-products/1-tube-joints-door-stop.png",
    id: "tube-joints-door-stop",
  },
  {
    title: "Panel Clamps & End Plugs",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54530A/",
    img: "/images/miscellaneous-products/2-panel-clamps-end-plugs.png",
    id: "panel-clamps-end-plugs",
  },
  {
    title: "Panel Mounting Profiles",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54540A/",
    img: "/images/miscellaneous-products/3-panel-mounting-profiles.png",
    id: "panel-mounting-profiles",
  },
  {
    title: "Hinges",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54550A/",
    img: "/images/miscellaneous-products/4-hinges.png",
    id: "hinges",
  },
  {
    title: "Door Handles",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54560A/",
    img: "/images/miscellaneous-products/5-door-handles.png",
    id: "door-handles",
  },
  {
    title: "Door Locks",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54570A/",
    img: "/images/miscellaneous-products/6-door-locks.png",
    id: "door-locks",
  },
];

const miscellaneousList = [
  { img: "/images/miscellaneous-products/door stop.jpg" },
  { img: "/images/miscellaneous-products/end plugs.jpg" },
  { img: "/images/miscellaneous-products/handle_01.jpg" },
  { img: "/images/miscellaneous-products/hinge_02.jpg" },
  { img: "/images/miscellaneous-products/knob_02.jpg" },
  { img: "/images/miscellaneous-products/knob_07.jpg" },
  { img: "/images/miscellaneous-products/ratched handles_02.jpg" },
  { img: "/images/miscellaneous-products/shaft collar_01.jpg" },
];

function MiscellaneousProducts() {
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    // else scroll to id
    // else {
    //   const id = hash.replace("#", "");
    //   const element = document.getElementById(id);
    //   setTimeout(() => {
    //     if (element) {
    //       element.scrollIntoView();
    //     }
    //   }, 100);
    // }
  }, [pathname]); // do this on route change

  return (
    <React.Fragment>
      {id === undefined ? (
        <React.Fragment>
          <div className="mb-5">
            <h2 className="text-secondary fw-bold mb-4">
              {conveyingProductsList[10].title}
            </h2>
            <p className="text-secondary mb-4">
              {conveyingProductsList[10].description}
            </p>
            <div className="img-box shadow-sm">
              <img
                className="img-fluid"
                src="/images/miscellaneous-products/0-index-miscellaneous-products.png"
                alt="miscellaneous-products"
              />
            </div>
          </div>

          <div className="row row-cols-3 row-cols-md-4 my-5">
            {miscellaneousList.map((el, index) => (
              <div key={index} className="col mb-3">
                <img className="img-fluid" src={el.img} alt="chains example" />
              </div>
            ))}
          </div>

          {/* types of */}
          <div className="my-4">
            <h4 className="text-secondary border-bottom border-2 border-secondary pb-2 mb-4">
              INDEX
            </h4>
            <ul className="">
              {list.map((item, index) => (
                <li key={index} className="fs-6 mb-2">
                  <Link className="py-2 d-block" to={`${pathname}/${item.id}`}>
                    • {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </React.Fragment>
      ) : (
        <ul>
          {list
            .filter((item) => item.id === id)
            .map((item, index) => (
              <li id={item.id} key={index} className="bg-white py-2 mb-5">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <h3 className="text-secondary fw-bold mb-3 mb-md-4">
                    {item.title}
                  </h3>
                </div>
                <div className="img-box">
                  <img className="img-fluid" src={item.img} alt="" />
                </div>
                <SmartguideBtn url={item.link} />
              </li>
            ))}
        </ul>
      )}
    </React.Fragment>
  );
}

export default MiscellaneousProducts;
