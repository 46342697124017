const conveyingProductsList = [
  {
    title: "Chains",
    img: "/images/productsCircleThumbnail/chains.png",
    description:
      "Conveyor chains are engineered to high quality and performance standards. Wear resistance, mechanical strength, operating temperature and coefficient of friction are all important considerations.",
    link: "/products/chains",
  },
  {
    title: "Modular Belts",
    img: "/images/productsCircleThumbnail/modular-belts.png",
    description:
      "Modular Plastic Belts provide the capability for wider conveying surfaces in addition to increased load ratings versus chain. Our broad offering of standard and heavy duty models provide a multitude of product conveying solutions.",
    link: "/products/Modular-belts",
  },
  {
    title: "Wear Strips",
    img: "/images/productsCircleThumbnail/wear-strips.png",
    description:
      "Wear strip system that solves conveyor return problems associated with metal and plastic slat top chains and wide modular type belts.",
    link: "/products/wear-strips",
  },
  {
    title: "Curves / Tracks",
    img: "/images/productsCircleThumbnail/curves-tracks.png",
    description:
      "Our straight and corner modules are designed to guide side flexing steel and plastic conveyor chains.",
    link: "/products/curves-tracks",
  },
  {
    title: "Chain & Belt Return Systems",
    shortTitle: "Return Systems",
    img: "/images/productsCircleThumbnail/return-system.png",
    description:
      "Our conveyor chain return rollers are made from impact resistant material. They are highly resistant to chemicals and have self lubricating characteristics. A urethane sleeve or ribbed under cover option is available. This allows for better traction, low noise and long service life.",
    link: "/products/chain-belt-return-systems",
  },
  {
    title: "Frame & Structure Supports",
    shortTitle: "Frame Structure",
    img: "/images/productsCircleThumbnail/frame-structure-supports.png",
    description:
      "Our extremely versatile range of fixed and articulated leveling feet provide machine support and securing options for almost any industry. These products are used in applications varying from light duty pharmaceutical and cosmetic industries to chemically corrosive or food and beverage wash down applications.",
    link: "/products/frame-structure-supports",
  },
  {
    title: "Side Guide Brackets & Accessories",
    shortTitle: "Brackets / Acc",
    img: "/images/productsCircleThumbnail/side-guide-brackets-acc.png",
    description:
      "Our System Plast® brackets are part of a family of products that work together to make guide rail adjusting easy. A variety of bracket styles and materials combined with accessories such as spacers, knobs, and adjusting rods are designed for maximum versatility.",
    link: "/products/side-guide-brackets-accessories",
  },
  {
    title: "Side Guide Solutions",
    shortTitle: "Guide Solutions",
    img: "/images/productsCircleThumbnail/side-guide-solution.png",
    description:
      "Our bead or roller guides and combiners provide excellent problem solutions. Used for areas on a conveying line where products tend to crowd together or jam. Side guide brackets in thermoplastic and stainless steel materials help guide products through the conveying process.",
    link: "/products/side-guide-solutions",
  },
  {
    title: "Levelers",
    img: "/images/productsCircleThumbnail/levelers.png",
    description:
      "Regal offers an extremely versatile range of fixed and articulated leveling feet. This offering provides machine support and securing options for almost any industry. These multiple options are available to meet specific conveyor requirements.",
    link: "/products/levelers",
  },
  {
    title: "Bearings",
    img: "/images/productsCircleThumbnail/bearings.png",
    description:
      "System Plast® modular bearing assemblies are available for harsh wash down environments often found in food and beverage processing. Units consist of sealed and lubricated imperial and metric bearings contained in plastic housings.",
    link: "/products/Bearings",
  },
  {
    title: "Miscellaneous Products",
    shortTitle: "Miscellaneous Products",
    img: "/images/productsCircleThumbnail/miscellaneous-products.png",
    description:
      "Miscellaneous products consist of a large number of different accessories. These accessories allow for most any conveyor requirements and design needs. These include: Machine guarding, panel clamps, tube joints, mounting profiles, handles, door stops, hinges, door locks, end plugs etc.",
    link: "/products/miscellaneous-products",
  },
];

export default conveyingProductsList;
