import React, { useState, useEffect } from "react";
import MessageBox from "../components/MessageBox";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useHistory } from "react-router-dom";

import { db } from "../firebase/firebase.config";
import { Timestamp } from "firebase/firestore";

import { collection, addDoc } from "firebase/firestore";

import { useDispatch } from "react-redux";
import { listArticles } from "../action/articleActions";

function CreateArticleScreen() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [error, setError] = useState("");

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // do this on route change

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "articles"), {
        title: title,
        subtitle: subtitle,
        htmlBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        date: Timestamp.now(),
      });
      dispatch(listArticles());
      history.goBack();
    } catch (e) {
      setError("Error adding article" + e);
    }
  };

  const cancelHandler = () => {
    history.goBack();
  };

  return (
    <div className="container px-4 p-3 my-4 my-lg-5 constraint-container-size">
      {error && <MessageBox variant="danger" children={error} />}
      <div className="mb-5">
        <h2 className="">Creating A New Article</h2>
      </div>

      <form className="" onSubmit={submitHandler}>
        <div className="form-group mb-4">
          <label htmlFor="title" className="fs-4">
            Title
          </label>
          <input
            type="text"
            placeholder="Enter Article Title "
            id="title"
            onChange={(e) => setTitle(e.target.value)}
            required
            className="form-control form-control-lg"
          />
          <small id="emailHelp" class="form-text text-muted">
            <span className="text-danger">*</span> This field is required.
          </small>
        </div>
        <div className="form-group mb-4">
          <label htmlFor="subtitle" className="fs-4">
            Subtitle
          </label>
          <input
            type="text"
            placeholder="Enter Article Subtitle "
            id="subtitle"
            onChange={(e) => setSubtitle(e.target.value)}
            className="form-control form-control-lg"
          />
          <small id="emailHelp" class="form-text text-muted">
            <span className="text-success">*</span> Optional - This field is
            displayed below the title of the article list. Not in article
            itself.
          </small>
        </div>
        <div className="form-group mb-5">
          <label htmlFor="text" className="fs-4">
            Main Text
          </label>
          <div
            className="border border-1 border-secondary rounded editor"
            id="text"
          >
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              localization={{
                locale: "ko",
              }}
              onEditorStateChange={onEditorStateChange}
            />
          </div>
          <small id="emailHelp" class="form-text text-muted">
            <span className="text-danger">*</span> This field is required.
          </small>
        </div>

        <div className="text-end">
          <button type="submit" className="btn btn-primary me-2">
            Post a Article
          </button>
          <button className="btn btn-secondary" onClick={cancelHandler}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateArticleScreen;
