import React from "react";
import ContactUs from "./ContactUs";

function ContactForm() {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="text-center">
            <h2 className="mb-5">Contact Us</h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-md-5 mb-4">
          <div className="contact-item d-flex mb-3">
            <div className="icon fs-4 text-dark">
              <i className="fas fa-envelope"></i>
            </div>
            <div className="text ms-3">
              <h3 className="fs-5">Email</h3>
              <p className="text-muted">esaehan@naver.com</p>
            </div>
          </div>

          <div className="contact-item d-flex mb-3">
            <div className="icon fs-4 text-dark">
              <i className="fas fa-phone"></i>
            </div>
            <div className="text ms-3">
              <h3 className="fs-5">CALL</h3>
              <p className="text-muted">+82 32-624-1954~9</p>
            </div>
          </div>

          {/* <div className="contact-item d-flex mb-3">
            <div className="icon fs-2 text-dark">
              <i className="fas fa-mobile-alt "></i>
            </div>
            <div className="text ms-3">
              <h3 className="fs-5">TEL</h3>
              <p className="text-muted">032-624-1954</p>
            </div>
          </div> */}

          <div className="contact-item d-flex mb-3">
            <div className="icon fs-4 text-dark">
              <i className="fas fa-phone"></i>
            </div>
            <div className="text ms-3">
              <h3 className="fs-5">FAX</h3>
              <p className="text-muted">+82 32-624-1957</p>
            </div>
          </div>

          <div className="contact-item d-flex">
            <div className="icon fs-4">
              <i className="fas fa-map-marker-alt text-dark"></i>
            </div>
            <div className="text ms-3">
              <h3 className="fs-5">Visit Office</h3>
              <p className="text-muted">
                101-310, 397 Seokcheon-ro, Bucheon-si <br />
                South Korea (14449)
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <ContactUs note={false} />
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
