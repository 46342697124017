import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MessageBox from "../components/MessageBox";

import { useSelector, useDispatch } from "react-redux";
import { listArticles } from "../action/articleActions";

import { useLocation, useHistory } from "react-router-dom";

function ArticlesScreen() {
  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const articleList = useSelector((state) => state.articleList);
  const { articles, loading, error } = articleList;

  // pagination
  const articlesPerPage = 5;

  const [numOfPages, setNumOfPages] = useState();

  const { search } = useLocation("page");
  const currentPageNum = Number(search.split("=")[1]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // do this on route change

  // read all articles from db
  useEffect(() => {
    if (!articles) {
      dispatch(listArticles());
    }
    if (articles) {
      setNumOfPages(Math.ceil(articles.length / articlesPerPage));
    }
  }, [articles]);

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  return (
    <div className="container px-4 p-3 my-4 my-lg-5 constraint-container-size">
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : loading ? (
        <></>
      ) : (
        <>
          <div className="mb-3 pb-2 d-flex align-items-center justify-content-between border-secondary border-bottom">
            <h2 className="">Articles</h2>
            {userInfo && (
              <Link className="btn btn-outline-dark" to="/create-article">
                <i class="fas fa-plus"></i>
              </Link>
            )}
          </div>

          <p className="text-secondary mb-5">
            {articles.length} articles found
          </p>

          <ul className="mb-5">
            {articles
              .slice(
                (currentPageNum - 1) * articlesPerPage,
                (currentPageNum - 1) * articlesPerPage + articlesPerPage
              )
              .map((article, index) => (
                <li key={index} className="mb-4">
                  <div>
                    {/* title */}
                    <Link
                      className="fs-4 d-block mb-2 text-dark"
                      to={`/articles/${article.id}?backTo=${currentPageNum}`}
                    >
                      {article.title}
                    </Link>
                    {/* subtitle */}
                    {article.subtitle && (
                      <h6 className="text-muted">
                        {truncateString(article.subtitle, 30)}
                      </h6>
                    )}
                    {/* date */}
                    <small className="text-muted">
                      Updated At :{" "}
                      {article.date.toDate().toString().substr(4, 11)}
                    </small>
                    {/* <p className="text-secondary">
                  {truncateString(article.body, 90)}
                </p> */}
                  </div>
                </li>
              ))}
          </ul>
          {/* pagination */}
          <ul className="pagination">
            <li className={`page-item ${currentPageNum <= 1 && "disabled"}`}>
              <Link
                className="page-link"
                to={`/articles?page=${currentPageNum - 1}`}
              >
                Previous
              </Link>
            </li>
            {currentPageNum > 1 && (
              <li className="page-item">
                <Link
                  className="page-link"
                  to={`/articles?page=${currentPageNum - 1}`}
                >
                  {currentPageNum - 1}
                </Link>
              </li>
            )}
            <li className="page-item active" aria-current="page">
              <span class="page-link">{currentPageNum}</span>
            </li>
            {currentPageNum < numOfPages && (
              <li className="page-item">
                <Link
                  className="page-link"
                  to={`/articles?page=${currentPageNum + 1}`}
                >
                  {currentPageNum + 1}
                </Link>
              </li>
            )}

            <li
              className={`page-item ${
                currentPageNum >= numOfPages && "disabled"
              }`}
            >
              <Link
                className="page-link"
                to={`/articles?page=${currentPageNum + 1}`}
              >
                Next
              </Link>
            </li>
          </ul>
        </>
      )}
    </div>
  );
}

export default ArticlesScreen;
