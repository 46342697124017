import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import conveyingProductsList from "../components/conveyingProducts/conveyingProductsList";

function HomeScreen() {
  return (
    <React.Fragment>
      {/* banner */}
      {/* <div className="container">
        <Banner
          type={"shipping_banner"}
          mainText={"Free shipping on every order!"}
          subText={"Receive items in 3-6 business days"}
        />
      </div> */}

      {/* Main Header for mobile */}
      <div
        className="py-5 d-lg-none main-header-lg shadow-sm"
        style={{ backgroundImage: `url("/images/systemplast_products.JPG")` }}
      >
        <div className="row align-items-center align-content-center">
          <div className="col-md-6 text-lg-center">
            <div className="about-text">
              <p>SAEHAN SYSTEM</p>
              <h1 className="mb-4">All Kind of Conveyor Components</h1>
              <p className="text-secondary pe-4">
                With the highest quality conveyor parts, pursue a sustainable
                future.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Main Header for desktop */}
      <div className="container-xl d-none d-lg-block py-5">
        <div className="row align-items-center align-content-center px-4">
          <div className="col-md-5 text-lg-center">
            <div className="about-text">
              <p className="fs-3">SAEHAN SYSTEM</p>
              <h1 className="mb-4 font-xxl fw-bold gradient-text">
                All Kind of{" "}
                <span className="primary-color">Conveyor Components</span>
              </h1>
              <p className="text-secondary fs-4">
                With the <span className="fw-bold">highest quality</span>{" "}
                conveyor parts, pursue{" "}
                <span className="fw-bold"> a sustainable</span> future.
              </p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="about-img text-center">
              <img
                className="img-fluid"
                src="/images/header_all_0.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* partners site list */}
      <div className="mt-lg-5 shadow-sm vertical-gradient ">
        <div className="container">
          <div className="row text-center align-items-center justify-content-evenly">
            <h3
              style={{ fontFamily: "Times New Roman, serif" }}
              className="text-center mt-5 mb-5 mb-lg-0"
            >
              WITH TRUSTED PARTNERS
            </h3>
            <div className="col-6 col-md-3 col-xl-2">
              <div className="p-4">
                <div className="product-img my-2 fs-2 ">
                  <img
                    className="img-fluid"
                    src="/images/partners-logo/regalrexnord.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2">
              <div className="p-4">
                <div className="product-img my-2 fs-2">
                  <img
                    className="img-fluid"
                    src="/images/partners-logo/system_plast_logo.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2">
              <div className="p-3">
                <div className="product-img my-2 fs-2">
                  <img
                    className="img-fluid"
                    src="/images/partners-logo/valu-guide-transparent.png"
                    alt=""
                  />
                </div>
              </div>
            </div>{" "}
            {/* <div className="col-6 col-md-3 col-xl-2">
              <div className="p-3">
                <div className="product-img my-2 fs-2">
                  <img
                    style={{ height: 50 }}
                    className="img-fluid"
                    src="/images/partners-logo/Rexnord.png"
                    alt=""
                  />
                </div>
              </div>
            </div> */}
            <div className="col-6 col-md-3 col-xl-2">
              <div className="p-4">
                <div className="product-img my-2 fs-2">
                  <img
                    className="img-fluid"
                    src="/images/partners-logo/makitech.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main partner section */}
      <div className="container my-5 py-5 px-3">
        <div className="row align-items-center align-content-center text-center text-lg-start justify-content-around">
          <div className="col-6 col-md-5 col-lg-4 col-xl-4 col-xxl-3 mb-5 mb-md-5 mb-lg-3">
            <div className="about-img">
              <img
                className="img-fluid"
                src="/images/family-site-logo/system_plast_logo.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-8 col-xl-7 ps-lg-5">
            <div className="about-text">
              <h2 className="mb-4 display-6">
                System Plast® Conveying Systems
              </h2>
              <h5 className="mb-4 text-secondary">
                System Plast® brand is focused with a drive towards assisting
                customers achieve sustainability while lowering their total cost
                of ownership.
              </h5>
              <h5 className="text-secondary">
                System Plast® brand has a culture of innovation. Power
                Transmission Solutions™ continues support for this with strong
                technical capabilities and resources.
              </h5>
            </div>
          </div>
        </div>
      </div>

      {/* ModSort section */}
      <div
        className="bg-img sudo-back-drop"
        style={{
          backgroundImage: `url('/images/modsort-background.jpeg') `,
        }}
      >
        <div className="container-lg py-5 px-5">
          <div className="row" style={{ zIndex: "2", position: "relative" }}>
            <div className="col-11 col-lg-6">
              <h1 className="display-3 text-white">
                What <span className="display-1">if...</span>
              </h1>
              <p
                className="fs-2"
                style={{
                  color: "#fff",
                }}
              >
                <p className="d-block">
                  one integrated module could easily divert, transfer and sort
                  multiple package types?
                </p>
              </p>
            </div>
          </div>
        </div>

        <div
          className="container-lg mt-5 py-5"
          style={{ zIndex: "2", position: "relative" }}
        >
          <div className="row align-items-center align-content-center text-center text-md-start justify-content-between pb-md-5">
            <div className="col-12 col-md-10 col-lg-5 col-xl-5 ps-md-5 mb-4">
              <div className="about-img mb-3">
                <img
                  className="img-fluid w-50"
                  src="/images/modsort_logo.png"
                  alt=""
                />
              </div>
              <h2 className="mb-4 font-xxl gradient-text">
                Divert & transfer module
              </h2>
              <p className="text-white">
                <p className="fs-5">
                  Very small packs? Polybags? Not a problem for ModSort® divert
                  and transfer conveyor modules! The ModSort module can do left
                  and right sorting on the fly for a wide range of package types
                  and sizes. It is safe, quiet, versatile and cost effective.
                </p>
                {/* <p className="fs-6">
                The ModSort module can do left and right sorting on the fly for
                a wide range of package types and sizes. It is safe, quiet,
                versatile and cost effective.
              </p> */}
              </p>
              <Link
                to="/products/modsort"
                className="btn btn-lg text-white border px-4 py-2 mt-3"
              >
                See Details
              </Link>
            </div>
            <div className="col col-12 col-lg-6 mb-5 mb-md-3">
              <div className="about-img mb-md-4">
                <img
                  className="img-fluid"
                  src="/images/modsort_transparent.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Smart Guide Catalogue */}

      <div
        className="bg-darker bg-img py-md-1"
        style={{ backgroundImage: `url('/images/polygon-scatter.png')` }}
      >
        <div
          className="border-img-ab"
          style={{
            backgroundImage: `url('/images/smart-guide-upper-edge-2.png')`,
            height: "1rem",
          }}
        ></div>
        <div
          className="border-img-ab-lower"
          style={{
            backgroundImage: `url('/images/smart-guide-lower-edge-2.png')`,
          }}
        ></div>
        <div className="container-lg mb-5 mt-md-5 pt-4 pb-5 pb-md-4 px-4 px-lg-0">
          <div className="row align-items-center align-content-center text-center text-md-start justify-content-between mb-5">
            <div className="col-12 col-md-6 col-lg-5 col-xl-4 mb-5 mb-md-3">
              <div className="d-flex flex-column">
                <h2 className="gradient-text-blue">We're introducing!</h2>
                <h1 className="font-xxl py-2 gradient-text-blue mb-4">
                  Smart Guide Catalogue
                </h1>
                <p className="text-white fs-6 lh-lg">
                  With an excellent SmartGuide on-line, our clients have the
                  facility to find the product they require quickly, with full
                  technical details.
                </p>
              </div>
              <a
                href="https://www.systemplastsmartguide.com/INT/Smart-Guide/10110A/"
                to="/products/modsort"
                className="btn btn-lg text-white border px-4 py-2 mt-3"
              >
                Check it out
              </a>
            </div>

            <div className="col-12 col-md-6 shadow-sm rounded border bg-white">
              <a href="https://www.systemplastsmartguide.com/INT/Smart-Guide/10110A/">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="/images/system_plast_catalogue.png"
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          {/* smart guide item category */}
          <div className="d-none d-md-block">
            <div className="row row-cols-2 row-cols-lg-4">
              {conveyingProductsList.map((product, index) => (
                <div key={index} className="col mb-4">
                  <div className="img-box mb-3">
                    <img
                      className="img-fluid"
                      src={product.img}
                      alt=""
                      style={{ width: 40 }}
                    />
                  </div>

                  <div className="col col-11">
                    <h5 className="text-white">{product.title}</h5>

                    <p style={{ color: "#e6e6e6" }}>{product.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* href="https://www.systemplastsmartguide.com/INT/Smart-Guide/10110A/" */}

      {/* conveyor items category */}
      <div className="container py-5 my-lg-3">
        {/* <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center">
              <h2 className="fw-bold mb-5">Products</h2>
            </div>
          </div>
        </div> */}
        <div className="row text-center">
          <div className="col-md-6 col-lg-4 mb-4">
            <a href="https://www.systemplastsmartguide.com/INT/Smart-Guide/11010A/">
              <div className="product shadow-sm p-4 rounded bg-white">
                <div className="product-img my-3 fs-2">
                  <img
                    style={{ width: 140 }}
                    className="img-fluid"
                    src="/images/products-thumbnail/chain.png"
                    alt=""
                  />
                </div>
                <h3 className="fs-4 py-2">Table Top Chains</h3>
                <p className="text-secondary">
                  Conveyor chains are engineered to high quality and performance
                  standards.
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <a href="https://www.systemplastsmartguide.com/INT/Smart-Guide/30010A/">
              <div className="product shadow-sm p-4 rounded bg-white">
                <div className="product-img my-3 fs-2">
                  <img
                    style={{ width: 162 }}
                    className="img-fluid"
                    src="/images/products-thumbnail/modular-belts-transparent.png"
                    alt=""
                  />
                </div>
                <h3 className="fs-4 py-2">Modular Belts</h3>
                <p className="text-secondary">
                  Modular Plastic Belts provide the capability for wider
                  conveying surfaces.
                </p>
              </div>
            </a>
          </div>
          {/* <div className="col-md-6 col-lg-4 mb-4">
            <a href="https://www.systemplastsmartguide.com/INT/Smart-Guide/11040A/">
              <div className="product shadow-sm p-4 rounded bg-white">
                <div className="product-img my-3 fs-2">
                  <img
                    style={{ width: 150 }}
                    className="img-fluid rounded-circle"
                    src="/images/products-thumbnail/sprocket_transparent.png"
                    alt=""
                  />
                </div>
                <h3 className="fs-4 py-2">Guide Rail</h3>
                <p className="text-secondary">
                  System Plast® offers all the layers for your complete conveyor
                  assembly.
                </p>
              </div>
            </a>
          </div> */}
          <div className="col-md-6 col-lg-4 mb-4">
            <a href="https://www.systemplastsmartguide.com/INT/Smart-Guide/57015A/">
              <div className="product shadow-sm p-4 rounded bg-white">
                <div className="product-img my-3 fs-2">
                  <img
                    style={{ width: 140 }}
                    className="img-fluid"
                    src="/images/products-thumbnail/modsort_transparent.png"
                    alt=""
                  />
                </div>
                <h3 className="fs-4 py-2">ModSort</h3>
                <p className="text-secondary">
                  ModSort is an innovative low-noise, low voltage transfer and
                  diverter station
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <a href="https://www.systemplastsmartguide.com/INT/Smart-Guide/56010A/">
              <div className="product shadow-sm p-4 rounded bg-white">
                <div className="product-img my-3 fs-2">
                  <img
                    style={{ width: 140 }}
                    className="img-fluid rounded-circle"
                    src="/images/products-thumbnail/bearing_transparent.png"
                    alt=""
                  />
                </div>
                <h3 className="fs-4 py-2">Bearing</h3>
                <p className="text-secondary">
                  Bearing assemblies are available for harsh wash down
                  environments.
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="product shadow-sm p-4 rounded bg-white">
              <div className="product-img my-3 fs-2">
                <img
                  style={{ width: 150 }}
                  className="img-fluid rounded-circle"
                  src="/images/products-thumbnail/sprocket_transparent.png"
                  alt=""
                />
              </div>
              <h3 className="fs-4 py-2 text-secondary">Conveyor Components</h3>
              <p className="text-secondary">
                System Plast® offers all the layers for your complete conveyor
                assembly.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form Component */}
      <ContactForm />
    </React.Fragment>
  );
}

export default HomeScreen;
