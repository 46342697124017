import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import { db } from "../firebase/firebase.config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { useDispatch } from "react-redux";
import { listArticles } from "../action/articleActions";

function EditArticleScreen() {
  const { id: articleId } = useParams();

  const history = useHistory();

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [editorState, setEditorState] = useState();

  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // do this on route change

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const docRef = doc(db, "articles", articleId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setTitle(docSnap.data().title);
          setSubtitle(docSnap.data().subtitle);

          const html = docSnap.data().htmlBody;
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
        } else {
          // doc.data() will be undefined in this case
          setError("Doc doesn't exist");
        }
      } catch (error) {
        setError("Failed to load the article." + error);
      }
    };

    fetchArticle();
  }, []);

  // when user hit the edit button
  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const docRef = doc(db, "articles", articleId);

      await updateDoc(docRef, {
        title: title,
        subtitle: subtitle,
        htmlBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        date: Timestamp.now(),
      });

      history.goBack();
      history.goBack();
      dispatch(listArticles());
    } catch (error) {
      setError("Failed to edit the article" + error);
    }
  };

  const cancelHandler = (e) => {
    history.goBack();
    history.goBack();
  };

  return (
    <div className="container px-4 p-3 my-4 my-lg-5 constraint-container-size">
      <div className="mb-4 border-bottom border-2 border-dark pb-2 d-flex align-items-center justify-content-between">
        <h1 className="">Edit Article</h1>
      </div>

      {error && <p>{error}</p>}

      <form className="" onSubmit={submitHandler}>
        <div className="border-bottom border-2 border-dark pb-2 mb-5">
          <div className=" text-end mb-4 text-danger">
            In Mobile, especially <strong>Safari Browser</strong>, <br />
            Please <strong>CLICK</strong> each buttons that you want to apply{" "}
            <strong>TWICE</strong>. <br /> Sorry for the inconvenience for a
            while.{" "}
          </div>
          <div className="text-end mb-4 border-dark">
            <button type="submit" className="btn btn-warning me-2">
              Edit a Article
            </button>
            <span className="btn btn-secondary" onClick={cancelHandler}>
              Cancel
            </span>
          </div>
        </div>

        <div className="form-group mb-4">
          <label htmlFor="title" className="fs-4">
            Title
          </label>
          <input
            type="text"
            placeholder="Enter Article Title "
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="form-control form-control-lg"
          />
          <small id="emailHelp" class="form-text text-muted">
            <span className="text-danger">*</span> This field is required.
          </small>
        </div>
        <div className="form-group mb-4">
          <label htmlFor="subtitle" className="fs-4">
            Subtitle
          </label>
          <input
            type="text"
            placeholder="Enter Article Subtitle "
            id="subtitle"
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
            className="form-control form-control-lg"
          />
          <small id="emailHelp" class="form-text text-muted">
            <span className="text-success">*</span> Optional - This field is
            displayed below the title of the article list. Not in article
            itself.
          </small>
        </div>
        <div className="form-group mb-5">
          <label htmlFor="text" className="fs-4">
            Main Text
          </label>
          <div
            className="border border-1 border-secondary rounded editor"
            id="text"
          >
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              localization={{
                locale: "ko",
              }}
              onEditorStateChange={onEditorStateChange}
            />
          </div>
          <small id="emailHelp" class="form-text text-muted">
            <span className="text-danger">*</span> This field is required.
          </small>
        </div>
      </form>
    </div>
  );
}

export default EditArticleScreen;
