import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import SmartguideBtn from "../SmartguideBtn";
import Video from "../Video";

const list = [
  {
    title: "Right Angle Transfer Module",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/57015A/",
    img: "/images/modSort/0-right-angle-transfer-module.png",
    id: "right-angle-transfer-module",
  },
];

const videoList = [
  {
    videoTitle: "ModSort Station Solves Transfer and Diversion Problems",
    videoDescription:
      "The ModSort station is a low-noise, low voltage modular transfer/diverter station. It can easily integrate with new or existing systems, eliminating the need for a lift, pneumatics, or Z-direction position feedback devices.",
    videoUrl: "https://youtu.be/AIxn-DO2Zeg",
  },
  {
    videoTitle: "System Plast® Modsort® Power and Control Options",
    videoDescription:
      "Very small packs? Polybags? Not a problem for ModSort® divert and transfer conveyor modules, with unlimited motion control in a simple modular package. The ModSort module can do left and right sorting on the fly for a wide range of package types and sizes.",
    videoUrl: "https://www.youtube.com/watch?v=7kj18FhTGDA",
  },
];

const ListStyle = ({ children }) => {
  return (
    <li className="d-flex mb-3">
      <div className="me-2">•</div>
      <div>{children}</div>
    </li>
  );
};

function ModSort() {
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    // else scroll to id
    // else {
    //   const id = hash.replace("#", "");
    //   const element = document.getElementById(id);
    //   setTimeout(() => {
    //     if (element) {
    //       element.scrollIntoView();
    //     }
    //   }, 100);
    // }
  }, [pathname]); // do this on route change

  return (
    <React.Fragment>
      {id === undefined ? (
        <React.Fragment>
          <div className="mb-5">
            <h2 className="text-secondary fw-bold mb-4">
              ModSort Divert & Transfer Module
            </h2>
            <p className="text-secondary mb-5">
              The ModSort station is an innovative low-noise, low voltage
              modular transfer and diverter station. It can easily integrate
              with new or existing material handling systems, eliminating the
              need for a lift, pneumatics, or Z-direction position feedback
              devices, and can help achieve energy savings from 50-60% when
              integrated with a motorized roller-based system.
            </p>

            {/* what if section */}
            <div className="row mb-5 align-items-center">
              <div className="mb-3 col-10 col-md-6">
                <h2>
                  <span style={{ color: "#007ec3", fontSize: "3.5rem" }}>
                    {" "}
                    What if
                  </span>{" "}
                  <br />
                  <p style={{ lineHeight: "3rem" }}>
                    One integrated module could easily divert, transfer and sort
                    multiple package types ?
                  </p>
                </h2>
              </div>
              <div className="col-9 col-md-6">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="/images/modSort/modsort_transparent.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            {/* what it does?  */}
            <div className="mb-5">
              <h2 className="mb-4">What it Does ?</h2>
              <div className="row">
                <div className="col-8 col-sm-6">
                  <ul>
                    <ListStyle children={"Receives product"}></ListStyle>
                    <ListStyle
                      children={"Passes product straight through"}
                    ></ListStyle>
                    <ListStyle
                      children={"Diverts product left or right"}
                    ></ListStyle>

                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>
                        Can be used to divert in two different modes: <br /> -
                        Stop and divert full 90 degrees <br /> - Divert on the
                        fly
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Features and Benefits */}
            <div className="my-5">
              <h2 className="mb-4">ModSort Features and Benefits</h2>
              <div className="row">
                <div className="col-8 col-sm-6">
                  <ul>
                    <ListStyle
                      children={
                        "Can be easily integrated into existing traditional or motorized roller conveyor systems"
                      }
                    ></ListStyle>
                    <ListStyle
                      children={"Ability to transfer a wide array of products"}
                    ></ListStyle>
                    <ListStyle
                      children={
                        "Mechanical lift unneeded (only two means of motion required)"
                      }
                    ></ListStyle>
                    <ListStyle
                      children={
                        "Run smaller packages without 'gaps' associated with pop-up transfer modules"
                      }
                    ></ListStyle>
                  </ul>
                </div>
                <div className="col-8 col-sm-6">
                  <ul>
                    <ListStyle
                      children={
                        "Ideal for handling polybags and smaller packages"
                      }
                    ></ListStyle>
                    <ListStyle
                      children={
                        "Utilizes unique System Plast 2253 Roller Top belt technology"
                      }
                    ></ListStyle>

                    <ListStyle
                      children={"Zero contact, zero pressure accumulation"}
                    ></ListStyle>
                    <ListStyle
                      children={
                        "Low noise and low voltage (24VDC, no conduit required)"
                      }
                    ></ListStyle>
                    <ListStyle
                      children={"Run on demand technology"}
                    ></ListStyle>
                  </ul>
                </div>
              </div>
            </div>
            {/* modSort Videos section */}
            <div className="row row-cols-1 row-cols-xxl-2">
              {videoList.map((video, index) => (
                <Video key={index} video={video} modifyMode={false} />
              ))}
            </div>
            {/* How it Works? */}
            <div className="row mb-5 justify-content-between">
              <div className="mb-3 col-10 col-md-6">
                <h2 className="mb-4">How it Works ?</h2>
                <p className="fs-5">
                  System Plast® 2253RT belt (blue) used as a transfer belt to
                  receive and pass product through or when the divert belt
                  (black) is activated diverts packages left or right. Both
                  belts are driven by industry standard MDR technology.
                </p>
              </div>
              <div className="col-9 col-md-6">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="/images/modSort/modsort-how-works.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* application section */}
            <div>
              <div className="img-box mb-5">
                <img
                  className="img-fluid"
                  src="/images/modSort/application-ex-1.png"
                  alt="modSort"
                />
              </div>
            </div>
            <div className="img-box mb-5">
              <img
                className="img-fluid"
                src="/images/modSort/application-ex.png"
                alt="modSort"
              />
            </div>
          </div>
          {/* types of */}
          <div className="my-4">
            <h4 className="text-secondary border-bottom border-2 border-secondary pb-2 mb-4">
              INDEX
            </h4>
            <ul className="">
              {list.map((item, index) => (
                <li key={index} className="fs-6 mb-2">
                  <Link className="py-2 d-block" to={`${pathname}/${item.id}`}>
                    • {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </React.Fragment>
      ) : (
        <ul>
          {list
            .filter((item) => item.id === id)
            .map((item, index) => (
              <li id={item.id} key={index} className="bg-white py-2 mb-5">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <h3 className="text-secondary fw-bold mb-3 mb-md-4">
                    {item.title}
                  </h3>
                </div>
                <div className="img-box">
                  <img className="img-fluid" src={item.img} alt="" />
                </div>
                <SmartguideBtn url={item.link} />
              </li>
            ))}
        </ul>
      )}
    </React.Fragment>
  );
}

export default ModSort;
