import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

function LeafletMap() {
  return (
    <div className="leaflet-container shadow-sm">
      <MapContainer
        center={[37.518653, 126.765287]}
        zoom={14}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[37.518653, 126.765287]}>
          <Popup>SAEHAN</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default LeafletMap;
