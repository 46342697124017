import React, { useEffect } from "react";
import BackgroundImageHeader from "../components/BackgroundImageHeader";
import LeafletMap from "../components/LeafletMap";
import ContactUs from "../components/ContactUs";

/* 
   address of the company and its representative offices;
   working hours and weekends;
   parking information; phone numbers;
   email addresses; link to Google Maps;
   driving directions;
   links to pages on social media;
   logins in WhatsApp and other messengers;
   photographs of the office, building, and staff;
   contacts and names of managers and employees.

   Read more on https://rondesign.agency/8-best-contact-us-pages/
*/

function ContactInformationScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // do this on route change

  return (
    <React.Fragment>
      {/* contact header part */}
      <BackgroundImageHeader />
      {/* contact information part */}
      <div className="container my-5 py-4 px-lg-5 d-flex flex-column">
        <div className="row">
          <div className="col-md-6 mb-5 me-5">
            <div className="title mb-5">
              <h2 className="mb-0">Contact Information</h2>
            </div>
            <div className="contact-item d-flex mb-3">
              <div className="icon fs-4 text-dark">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="text ms-3">
                <h3 className="fs-5">Email</h3>
                <p className="text-muted">esaehan@naver.com</p>
              </div>
            </div>

            <div className="contact-item d-flex mb-3">
              <div className="icon fs-4 text-dark">
                <i className="fas fa-phone"></i>
              </div>
              <div className="text ms-3">
                <h3 className="fs-5">CALL</h3>
                <p className="text-muted">+82 32-624-1954~9</p>
              </div>
            </div>

            {/* <div className="contact-item d-flex mb-3">
              <div className="icon fs-2 text-dark">
                <i className="fas fa-mobile-alt "></i>
              </div>
              <div className="text ms-3">
                <h3 className="fs-5">TEL</h3>
                <p className="text-muted">+82-32-624-1954</p>
              </div>
            </div> */}

            <div className="contact-item d-flex mb-3">
              <div className="icon fs-4 text-dark">
                <i className="fas fa-phone"></i>
              </div>
              <div className="text ms-3">
                <h3 className="fs-5">FAX</h3>
                <p className="text-muted">+82 32-624-1957</p>
              </div>
            </div>

            <div className="contact-item d-flex">
              <div className="icon fs-4">
                <i className="fas fa-map-marker-alt text-dark"></i>
              </div>
              <div className="text ms-3">
                <h3 className="fs-5">Visit Office</h3>
                <p className="text-muted">
                  101-310, 397 Seokcheon-ro, Bucheon-si <br />
                  South Korea (14449)
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-5 mb-5">
            <div className="title mb-5 d-flex align-items-center">
              <i className="fas fa-map-marked-alt fs-2 me-3"></i>
              <h2 className="mb-0">Company Location</h2>
            </div>
            <LeafletMap />
          </div>
        </div>
        {/* contact us part */}
        <div className="row">
          <div className="col-md-7">
            <div className="title mb-4">
              <h2>Contact Us</h2>
            </div>

            <ContactUs note={true} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactInformationScreen;
