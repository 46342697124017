import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../components/gallery/Modal";
import Pictures from "../components/gallery/Pictures";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

import { storage } from "../firebase/firebase.config";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { db } from "../firebase/firebase.config";
import {
  collection,
  doc,
  addDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

function GalleryScreen() {
  const [galleryPic, setGalleryPic] = useState([]);
  const [imgName, setImgName] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState(null);

  const [selectedImg, setSelectedImg] = useState(null);

  const [downloadedImgUrl, setDownloadedImgUrl] = useState(null);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [modifyMode, setModifyMode] = useState(false);

  // load all the data from db
  useEffect(() => {
    setLoading(true);
    const readAllImgUrlsFromDB = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "images"));

        let images = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          images.push({ id: doc.id, ...doc.data() });
        });

        setGalleryPic(images);
        setLoading(false);
      } catch (error) {
        setErrorUpload(
          "Error occurred from reading images. msg : " + error?.message
        );
        setLoading(false);
      }
    };

    readAllImgUrlsFromDB();
  }, []);

  // once storage download url is created then data will be stored into firestore db
  useEffect(() => {
    if (downloadedImgUrl) {
      setLoadingUpload(true);
      const addImgUrlToDB = async () => {
        try {
          const docRef = await addDoc(collection(db, "images"), {
            imgName: imgName,
            imgUrl: downloadedImgUrl,
          });

          setGalleryPic([
            ...galleryPic,
            {
              id: docRef.id,
              imgUrl: downloadedImgUrl,
              imgName: imgName,
            },
          ]);

          setDownloadedImgUrl(null);
          setImgName(null);
          setLoadingUpload(false);
        } catch (e) {
          setDownloadedImgUrl(null);
          setErrorUpload("Error adding document" + e);
          setLoadingUpload(false);
        }
      };

      addImgUrlToDB();
    }
  }, [downloadedImgUrl]);

  const uploadFileHandler = async (e) => {
    if (!userInfo) {
      alert("Only for Authorized User");
      return;
    }

    const file = e.target.files[0];

    // only image file allowed
    if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      alert("Only image file allowed");
      return;
    }

    const filename = Date.now().toString() + "_" + file.name;

    const imageRef = ref(storage, "images/" + filename);

    const uploadTask = uploadBytesResumable(imageRef, file);

    const unsubscribe = uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // const progress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        // switch (snapshot.state) {
        //   case "paused":
        //     console.log("Upload is paused");
        //     break;
        //   case "running":
        //     console.log("Upload is running");
        //     break;
        // }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            setErrorUpload("Doesn't have permission to do this (unauthorize)");
            break;
          case "storage/canceled":
            // User canceled the upload
            setErrorUpload("Canceled the upload");
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            setErrorUpload(
              "Unknown error occurred, inspect error.serverResponse"
            );
            break;
        }
        // unsubscribe event
        unsubscribe();
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgName(filename);
          setDownloadedImgUrl(downloadURL);
        });
        // unsubscribe event
        unsubscribe();
      }
    );
  };

  const deletePicture = async (pic) => {
    const picArray = galleryPic.filter((el) => el.id !== pic.id);
    setGalleryPic(picArray);

    // delete file data from firestore
    await deleteDoc(doc(db, "images", pic.id));

    // delete file data from storage
    const desertRef = ref(storage, "images/" + pic.imgName);
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  };

  return (
    <React.Fragment>
      <div className="container mt-5">
        <div className="d-flex flex-column justify-content-center align-items-center mb-4 pb-2">
          <div className="d-flex align-items-center mb-3">
            <h1 className="">Photo Gallery</h1>
          </div>
          <p className="text-secondary fw-light mb-4 px-5 text-center">
            Can see photos related to our company.
          </p>
          <div className="d-flex align-items-center">
            {/* Only Admin */}
            {userInfo && modifyMode && (
              <div className="">
                {loadingUpload ? (
                  <LoadingBox />
                ) : errorUpload ? (
                  <MessageBox variant={"danger"}>{errorUpload}</MessageBox>
                ) : (
                  <label className="btn pic-upload-btn">
                    Upload a Photo
                    <input
                      type="file"
                      id="imageFile"
                      label="Choose Image"
                      onChange={uploadFileHandler}
                    />
                  </label>
                )}
              </div>
            )}

            {userInfo && (
              <>
                {!modifyMode ? (
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setModifyMode(!modifyMode)}
                  >
                    Manage Photos
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-success ms-4"
                    onClick={() => setModifyMode(!modifyMode)}
                  >
                    Done
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <Pictures
          galleryPic={galleryPic}
          setSelectedImg={setSelectedImg}
          deletePicture={deletePicture}
          modifyMode={modifyMode}
        />
      </div>
      {/* Modal */}
      {selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </React.Fragment>
  );
}

export default GalleryScreen;
