import React, { useEffect, useState } from "react";
import Video from "../components/Video";
import { useSelector } from "react-redux";

import { db } from "../firebase/firebase.config";
import {
  collection,
  doc,
  addDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import MessageBox from "../components/MessageBox";

function VideoScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [modifyMode, setModifyMode] = useState(false);
  const [deletedId, setDeletedId] = useState("");

  // state related to video upload
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoUrlValidation, setVideoUrlValidation] = useState(false);

  //read all video info from DB
  useEffect(() => {
    setLoading(true);
    const readVideosFromDB = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "videos"));

        let videos = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          videos.push({ id: doc.id, ...doc.data() });
        });

        setVideos(videos);
        setLoading(false);
      } catch (error) {
        setLoadingError(
          "Error occurred from reading videos. msg : " + error?.message
        );
        setLoading(false);
      }
    };

    readVideosFromDB();
  }, []);

  const uploadHandler = async () => {
    try {
      const docRef = await addDoc(collection(db, "videos"), {
        videoUrl,
        videoTitle,
        videoDescription,
      });

      setVideos([
        {
          id: docRef.id,
          videoUrl: videoUrl,
          videoTitle: videoTitle,
          videoDescription: videoDescription,
        },
        ...videos,
      ]);

      console.log(docRef);

      setVideoUrl("");
      setVideoTitle("");
      setVideoDescription("");
      setUploadError("");
      setVideoUrlValidation(false);
    } catch (e) {
      setUploadError("Error adding document with msg: " + e);
      setVideoUrlValidation(false);
    }
  };

  const cancelHandler = () => {
    setVideoUrl("");
    setVideoTitle("");
    setVideoDescription("");
    setVideoUrlValidation(false);
  };

  function matchYoutubeUrl(url) {
    var p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      setVideoUrlValidation(true);
    } else {
      setVideoUrlValidation(false);
    }
  }

  const deleteHandler = async (id) => {
    const remaining = videos.filter((el) => el.id !== id);
    setVideos(remaining);
    try {
      // delete file data from firestore
      await deleteDoc(doc(db, "videos", id));
    } catch (error) {
      setDeleteError("Error occurred with message: " + error);
    }
  };

  const urlChangeHandler = (e) => {
    setVideoUrl(e.target.value);
    setVideoUrlValidation(false);
  };

  return (
    <div className="container p-3 mt-4 mt-lg-5">
      {/*  upload modal */}
      <div
        className="modal fade"
        id="uploadModal"
        tabIndex="-1"
        aria-labelledby="uploadModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="uploadModalLabel">
                Upload a Video
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-secondary text-bold">
              <div className="mb-3">
                Link validation and all fields are required. <br /> Please fill
                in all fields with appropriate value.
              </div>
              <div className="mb-3">
                <label htmlFor="videoLink" className="form-label">
                  Video Link (URL)
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Link"
                  id="videoLink"
                  value={videoUrl}
                  onChange={(e) => urlChangeHandler(e)}
                  aria-describedby="videoLink"
                />
                <div id="videoLink" className="form-text">
                  * This field is required. Only{" "}
                  <span className="text-danger fw-bold">Youtube </span>video
                  link will work.
                </div>
                <div className="mt-2 fw-bold">
                  {!videoUrlValidation ? (
                    <span className="text-danger me-3">Not validated yet</span>
                  ) : (
                    <span className="text-success me-3">
                      Validated Successfully!
                    </span>
                  )}

                  <button
                    className={`btn btn-success ${
                      videoUrlValidation && "disabled"
                    }`}
                    onClick={() => matchYoutubeUrl(videoUrl)}
                  >
                    Validate Link
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Video Title
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Title"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  aria-describedby="videoTitle"
                />
                <div id="videoTitle" className="form-text">
                  * This field is required.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="desc" className="form-label">
                  Video Description
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Description"
                  value={videoDescription}
                  onChange={(e) => setVideoDescription(e.target.value)}
                  aria-describedby="videoDescription"
                />
                <div id="videoDescription" className="form-text">
                  * This field is required.
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={() => cancelHandler()}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className={`btn btn-primary ${
                  !videoUrlValidation && "disabled"
                }`}
                data-bs-dismiss="modal"
                onClick={() => uploadHandler()}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* upload modal end */}

      {/*  Delete Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-danger" id="deleteModalLabel">
                Delete Video
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-secondary text-bold">
              This is an irreversible behavior. Are you sure to delete this?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => deleteHandler(deletedId)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Modal End */}

      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="fs-1" style={{ color: "#0D3E62" }}>
          Stories & Videos
        </div>

        <div>
          {userInfo && (
            <>
              {!modifyMode ? (
                <span className="" onClick={() => setModifyMode(!modifyMode)}>
                  <i className="fas fa-cog fs-1 icon-normal"></i>
                </span>
              ) : (
                <>
                  <span
                    className=""
                    aria-hidden="true"
                    data-bs-toggle="modal"
                    data-bs-target="#uploadModal"
                  >
                    <i class="fas fa-file-upload fs-1 icon-primary"></i>
                  </span>
                  <span
                    className="ms-4"
                    onClick={() => setModifyMode(!modifyMode)}
                  >
                    <i class="fas fa-check fs-1 icon-complete"></i>
                  </span>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {loadingError ? (
        <MessageBox variant="danger">{loadingError}</MessageBox>
      ) : uploadError ? (
        <MessageBox variant="danger">{uploadError}</MessageBox>
      ) : (
        deleteError && <MessageBox variant="danger">{deleteError}</MessageBox>
      )}
      <div className="row row-cols-1 row-cols-xl-2 py-4">
        {!loading &&
          videos.map((video, index) => (
            <Video
              key={index}
              video={video}
              modifyMode={modifyMode}
              setDeletedId={setDeletedId}
            />
          ))}
      </div>
    </div>
  );
}

export default VideoScreen;
