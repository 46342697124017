import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";

import { db } from "../firebase/firebase.config";
import { doc, getDoc, deleteDoc } from "firebase/firestore";

import parse from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { listArticles } from "../action/articleActions";
import MessageBox from "../components/MessageBox";

function ArticleScreen() {
  const { id: articleId } = useParams();
  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [article, setArticle] = useState(null);
  const [error, setError] = useState(null);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // do this on route change

  useEffect(() => {
    const fetchArticle = async () => {
      const docRef = doc(db, "articles", articleId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setArticle(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        setError("Doc doesn't exist");
      }
    };

    fetchArticle();
  }, [articleId]);

  // delete the data from firestore
  const deleteHandler = async () => {
    try {
      await deleteDoc(doc(db, "articles", articleId));
      // Send user back to articles page
      dispatch(listArticles());
      history.push(`/articles?page=1`);
    } catch (error) {
      setError("Failed to delete article." + error);
    }
  };

  const goBackHandler = () => {
    history.goBack();
  };

  // send user to edit page
  const editHandler = async () => {
    // Send user back to articles page
    history.push(`/articles/${articleId}/edit`);
  };

  return (
    <>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className="container px-4 p-3 my-4 my-lg-5 constraint-container-size">
          {article && (
            <div>
              {/* button group */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <span
                    className="fs-6 text-secondary pointer"
                    onClick={goBackHandler}
                  >
                    <i className="fas fa-chevron-left"> Back to Articles</i>
                  </span>
                </div>
                {userInfo && (
                  <div>
                    <Link className="me-3 fs-2" onClick={editHandler}>
                      <i className="far fa-edit"></i>
                    </Link>
                    <Link className="fs-2 text-danger" onClick={deleteHandler}>
                      <i className="far fa-trash-alt"></i>
                    </Link>
                  </div>
                )}
              </div>

              <div className="my-2">
                <h1 className="mb-4 display-5">{article.title}</h1>
                <h6 className="mb-4 text-secondary text-start text-sm-end">
                  Updated At : {article.date.toDate().toString().substr(4, 17)}
                </h6>
                <hr />
                <div className="my-5 articleHtml">
                  {article.htmlBody && parse(article.htmlBody)}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ArticleScreen;
