import React, { useState, useEffect } from "react";

function AboutUs() {
  const [lng, setLng] = useState("Eng");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // do this on route change

  return (
    <div className="container px-5 p-3 my-4 my-lg-5 constraint-container-size">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <i style={{ color: "#0D3E62" }} className="fas fa-city fs-1 me-3">
            {" "}
          </i>
          <span className="text-nowrap me-3 fs-1" style={{ color: "#0D3E62" }}>
            About Us
          </span>
        </div>
        <select
          class="form-control"
          style={{ width: 54 }}
          onChange={(e) => setLng(e.target.value)}
        >
          <option value={"Eng"}>Eng</option>
          <option value={"Kor"}>Kor</option>
          <option value={"Jap"}>Jap</option>
        </select>
      </div>
      <div className="img-box text-center my-4 my-lg-4 mb-lg-5">
        <img
          src="/images/about-us/about_us_img_0.jpg"
          alt=""
          className="img-fluid rounded"
        />
      </div>

      {lng === "Eng" ? (
        <p className="text-secondary">
          Thank you for visiting the best supplier of factory automation
          conveyor products in the Republic of Korea.
          <br />
          <br />
          Saehan System is a company that imports, supplies, and sells automated
          conveyor-related products. Our main supply partner is GERMANY (USA &
          ITALY) System Plast belonging to the world's leading "Regal Rexnord"
          Group, and is evaluated as a brand that handles the most used products
          in the global conveyor components market.
          <br />
          <br />
          Recently, We Mainly supply flat top chains, plastic modular belts, and
          conveyor components of the world's most used brands such as System
          plast, Rexnord, MCC, Marbett and Solus to many industries such as
          beverage industry, food industry, automobile etc.
          <br />
          <br />
          For the past 30 years or more, we have dealt only with
          conveyor-related products, and we have done our best to respond to the
          changes and needs of our customers in the face of many changes and
          developments around the environment, providing better products and
          services.
          <br />
          <br />
          Saehan System will always do its best to become a company that takes
          one step closer to customer needs and provides maximum satisfaction
          with reliable and suitable products.
          <br />
          <br />
          Please take a look around and contact us if you have any product or
          inquiry you need. Our family of Saehan Systems will continue to aspire
          to become a great partner for your company with an advanced selection,
          a variety of products, accurate delivery, and more economical prices.
          <br />
          <br />
          Thank you.
          <br />
          <br />
          A company pursuing a sustainable future with the highest quality
          conveyor parts.
          <br />
          <br />
          <strong>Ho-Dal Son, President of Saehan System Co., Ltd.</strong>
        </p>
      ) : lng === "Kor" ? (
        <p className="text-secondary">
          세계 최고의 공장 자동화 컨베이어 제품 취급 업체를 방문해 주셔서 대단히
          감사합니다.
          <br />
          <br />
          새한시스템은 자동화 컨베이어 관련 제품들을 수입하여 공급 및 판매하는
          회사입니다. 저희의 주 공급 파트너는 세계 굴지의 "Regal Rexnord" Group
          소속의 GERMANY (USA & ITALY) System Plast로서 세계 Conveyor Components
          시장에서 가장 많이 사용되는 제품을 취급하는 브랜드로 평가 받고
          있습니다.
          <br />
          <br />
          최근에는 System plast, Rexnord, MCC, Marbett, Solus와 같은 세계에서
          가장 많이 사용되는 브랜드의 Flat Top Chains, Plastic Modular Belts,
          Conveyor Components 등의 제품들을 Beverage industry, Food industry,
          Auto mobile과 같은 산업 전반에 걸쳐 공급하고 판매하고 있습니다.
          <br />
          <br />
          지난 30여년 동안 저희는 컨베이어 관련 제품만을 취급해 왔으며, 환경을
          둘러싼 많은 변화와 발전에 직면하여 고객의 변화와 요구에 부응하고자 더
          나은 제품과 서비스를 제공하며 업계에서 최선을 다 해오고 있습니다.
          <br />
          <br />
          새한 시스템은 항상 믿을 수 있는 적합한 제품들로 고객들의 필요에 한
          걸음 더 다가서고 최대의 만족을 드리는 회사로 나아갈 수 있도록 최선을
          다 하겠습니다.
          <br />
          <br />
          둘러보시고 필요한 제품이나 문의사항이 있으시면 연락을 부탁드립니다.
          저희 새한시스템 일동은 앞선 선택, 다양한 제품, 정확한 납기, 보다
          경제적인 가격으로 고객의 최고 파트너가 될 수 있도록 최선을
          다하겠습니다.
          <br />
          <br />
          감사합니다.
          <br />
          <br />
          최고 품질의 컨베이어 부품으로 지속 가능한 미래를 추구합니다.
          <br />
          <br />
          <strong>(주) 새한시스템 대표 이사 손 호 달</strong>
        </p>
      ) : (
        <p className="text-secondary">
          韓国のファクトリーオートメーションコンベア製品の最高のサプライヤーを訪問していただきありがとうございます。
          <br />
          <br />
          セハンシステムは、自動コンベア関連製品を輸入して供給し、販売している会社です。私たちの主なパートナーは世界屈指の
          "Regal Rexnord" グループ所属のGERMANY (USA & ITALY) System
          Plastとして世界コンベアコンポーネント市場で最も多く使用される製品を扱うブランドとして評価されています。
          <br />
          <br />
          最近では、System
          Plast、Rexnord、MCC、Marbett、Solusのような世界で最も多く使用されてブランドのFlat
          フラットトップチェーン、プラスチックモジュラーベルト、コンベアコンポーネントの製品を飲料
          業界、食品業界、自動車産業のような多くの産業全般にわたって供給して販売しています。
          <br />
          <br />
          過去30年以上にわたり、私たちは、コンベア関連製品だけを扱いおり、環境を
          取り巻く多くの変化と発展に直面し、顧客の変化とニーズに応えるため、より
          優れた製品とサービスを提供し、業界で最善を尽くしてきています。
          <br />
          <br />
          会社の製品を見ていただきながら、必要な製品やお問い合わせがございましたら、ご連絡をお願いいたします。私たちのセハンシステム一同は先進の選択、様々な製品、正確な納期、より経済的な価格でお客様の最高のパートナーになることができるように最善を尽くします。
          <br />
          <br />
          セハンシステムは、常に信頼できる適切な製品でお客様のニーズに一歩近寄って最大の満足を差し上げる会社に進むことができるように最善を尽くします。
          <br />
          <br />
          ありがとうございます。
          <br />
          <br />
          最高品質のコンベア部品で、持続可能な未来を求めます。
          <br />
          <br />
          <strong>（株）セハンシステム代表取締役, 孫 浩 達</strong>
        </p>
      )}
    </div>
  );
}

export default AboutUs;
