import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import SmartguideBtn from "../SmartguideBtn";
import conveyingProductsList from "./conveyingProductsList";

const list = [
  {
    title: "Straight Modular Belts",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/30010A/",
    img: "/images/modular-belts/0-straight-modular-belts.png",
    id: "straight-modular-belts",
  },

  {
    title: "Side Flexing Modular Belts",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/30012A/",
    img: "/images/modular-belts/1-sideflexing-modular-belts.png",
    id: "side-flexing-modular-belts",
  },
];

const modularBeltsList = [
  {
    img: "/images/modular-belts/kind-of-belts/straight-flat-top-0.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/straight-flat-top-1.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/straight-flat-top-2.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/straight-flat-top-3.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/straight-flush-grid-0.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/straight-flush-grid-1.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/straight-lbp-0.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/straight-rubber-inserts-0.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/straight-special-roller-0.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/side-flat-top-0.png",
  },
  {
    img: "/images/modular-belts/kind-of-belts/side-flush-grid-0.png",
  },
];

function ModularBelts() {
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    // else scroll to id
    // else {
    //   const id = hash.replace("#", "");
    //   const element = document.getElementById(id);
    //   setTimeout(() => {
    //     if (element) {
    //       element.scrollIntoView();
    //     }
    //   }, 100);
    // }
  }, [pathname]); // do this on route change

  return (
    <React.Fragment>
      {id === undefined ? (
        <React.Fragment>
          <div className="mb-4">
            <h2 className="text-secondary fw-bold mb-4">
              {conveyingProductsList[1].title}
            </h2>
            <p className="text-secondary mb-4">
              {conveyingProductsList[1].description}
            </p>
          </div>
          <div className="row row-cols-3 row-cols-md-4 my-4">
            {modularBeltsList.map((el, index) => (
              <div key={index} className="col">
                <img className="img-fluid" src={el.img} alt="chains example" />
              </div>
            ))}
          </div>
          {/* types of */}
          <div className="my-4">
            <h4 className="text-secondary border-bottom border-2 border-secondary pb-2 mb-4">
              TYPES OF MODULAR BELTS
            </h4>
            <ul className="mb-5">
              {list.map((item, index) => (
                <li key={index} className="fs-6 mb-2">
                  <Link className="py-2 d-block" to={`${pathname}/${item.id}`}>
                    • {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </React.Fragment>
      ) : (
        <ul>
          {/* <Link
            className="d-none d-sm-inline-block mb-4 btn btn-outline-secondary border-0 fs-5"
            to={pathname.slice(0, pathname.lastIndexOf("/"))}
          >
            Go Back
          </Link> */}

          {list
            .filter((item) => item.id === id)
            .map((item, index) => (
              <li id={item.id} key={index} className="bg-white py-2 mb-5">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <h3 className="text-secondary fw-bold mb-3 mb-md-4">
                    {item.title}
                  </h3>
                </div>
                <div className="img-box">
                  <img className="img-fluid" src={item.img} alt="" />
                </div>
                <SmartguideBtn url={item.link} />
              </li>
            ))}
        </ul>
      )}
    </React.Fragment>
  );
}

export default ModularBelts;
