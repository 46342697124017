import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import SmartguideBtn from "../SmartguideBtn";
import conveyingProductsList from "./conveyingProductsList";

const list = [
  {
    title: "Index Frame & Structure Supports",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54010A/",
    img: "/images/frame-structure-supports/0-index-frame-structure-supports.png",
    id: "index-frame-structure-supports",
  },
  {
    title: "Side Mounting Top Brackets",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54020A/",
    img: "/images/frame-structure-supports/1-side-mounting-top-brackets.png",
    id: "side-mounting-top-brackets",
  },
  {
    title: "Side Mounting Top Brackets with Expansion Plug",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54050A/",
    img: "/images/frame-structure-supports/3-side-mounting-top-brackets-with-expansion-plug.png",
    id: "side-mounting-top-brackets-with-expansion-plug",
  },
  {
    title: "Support Bases",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54030A/",
    img: "/images/frame-structure-supports/2-support-bases.png",
    id: "support-bases",
  },
  {
    title: "Support Heads",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54060A/",
    img: "/images/frame-structure-supports/4-support-heads.png",
    id: "support-heads",
  },
  {
    title: "Connecting Joints",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54070A/",
    img: "/images/frame-structure-supports/5-connecting-joints.png",
    id: "connecting-joints",
  },
  {
    title: "Connecting Joints - Stainless Steel",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/54080A/",
    img: "/images/frame-structure-supports/6-connecting-joints-stainless-steel.png",
    id: "connecting-joints-stainless-steel",
  },
];

function FrameStructureSupports() {
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    // else scroll to id
    // else {
    //   const id = hash.replace("#", "");
    //   const element = document.getElementById(id);
    //   setTimeout(() => {
    //     if (element) {
    //       element.scrollIntoView();
    //     }
    //   }, 100);
    // }
  }, [pathname]); // do this on route change

  return (
    <React.Fragment>
      {id === undefined ? (
        <React.Fragment>
          <div className="mb-5">
            <h2 className="text-secondary fw-bold mb-4">
              {conveyingProductsList[5].title}
            </h2>
            <p className="text-secondary mb-4">
              {conveyingProductsList[5].description}
            </p>
            <div className="img-box shadow-sm">
              <img
                className="img-fluid"
                src="/images/frame-structure-supports/0-index-frame-structure-supports.png"
                alt="frame-structure-supports"
              />
            </div>
          </div>
          {/* types of */}
          <div className="my-4">
            <h4 className="text-secondary border-bottom border-2 border-secondary pb-2 mb-4">
              INDEX
            </h4>
            <ul className="">
              {list.map((item, index) => (
                <li key={index} className="fs-6 mb-2">
                  <Link className="py-2 d-block" to={`${pathname}/${item.id}`}>
                    • {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </React.Fragment>
      ) : (
        <ul>
          {list
            .filter((item) => item.id === id)
            .map((item, index) => (
              <li id={item.id} key={index} className="bg-white py-2 mb-5">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <h3 className="text-secondary fw-bold mb-3 mb-md-4">
                    {item.title}
                  </h3>
                </div>
                <div className="img-box">
                  <img className="img-fluid" src={item.img} alt="" />
                </div>
                <SmartguideBtn url={item.link} />
              </li>
            ))}
        </ul>
      )}
    </React.Fragment>
  );
}

export default FrameStructureSupports;
