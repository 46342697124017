import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import ReCAPTCHA from "react-google-recaptcha";

import { storage } from "../firebase/firebase.config";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { db } from "../firebase/firebase.config";
import {
  collection,
  doc,
  addDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

import { Timestamp } from "firebase/firestore";

function ReferenceScreen() {
  const [references, setReferences] = useState([]);

  // file itself
  const [uploadFile, setUploadFile] = useState(null);

  // filename
  const [uploadFileName, setUploadFileName] = useState("");

  const [uploadFileSize, setUploadFileSize] = useState("");

  // file title to be displayed on the frontend
  const [uploadFileTitle, setUploadFileTitle] = useState("");

  // file type (format)
  const [uploadFileType, setUploadFileType] = useState("other");

  const fileRef = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState(null);

  const [downloadedUrl, setDownloadedUrl] = useState("");

  const [modifyMode, setModifyMode] = useState(false);

  const [verified, setVerified] = useState(false);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  // load all the data from db
  useEffect(() => {
    setLoading(true);
    const readAllReferences = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "references"));

        let referencesArray = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          referencesArray.push({ id: doc.id, ...doc.data() });
        });

        setReferences(referencesArray);
        setLoading(false);
      } catch (error) {
        setErrorUpload(
          "Error occurred from reading images. msg : " + error?.message
        );
        setLoading(false);
      }
    };

    readAllReferences();
  }, []);

  // once storage's download-url is created then data will be stored into firestore db
  useEffect(() => {
    if (downloadedUrl) {
      setLoadingUpload(true);
      const addReference = async () => {
        try {
          const docRef = await addDoc(collection(db, "references"), {
            referenceName: uploadFileName,
            referenceTitle: uploadFileTitle,
            referenceUrl: downloadedUrl,
            referenceType: uploadFileType,
            referenceSize: uploadFileSize,
            date: Timestamp.now(),
          });

          setReferences([
            ...references,
            {
              id: docRef.id,
              referenceName: uploadFileName,
              referenceTitle: uploadFileTitle,
              referenceUrl: downloadedUrl,
              referenceType: uploadFileType,
              referenceSize: uploadFileSize,
              date: Timestamp.now(),
            },
          ]);

          clearStateInput();
          setLoadingUpload(false);
        } catch (e) {
          clearStateInput();
          setErrorUpload("Error adding document" + e);
          setLoadingUpload(false);
        }
      };

      addReference();
    }
  }, [downloadedUrl]);

  const clearStateInput = () => {
    setUploadFileName("");
    setUploadFileTitle("");
    setDownloadedUrl("");
    setUploadFileType("other");
    fileRef.current.value = null;
  };

  const uploadFileHandler = async () => {
    if (!userInfo) {
      alert("Only for Authorized User");
      return;
    }

    if (!uploadFile) {
      alert("Please select file to be uploaded.");
      return;
    }

    setLoadingUpload(true);

    const filename = Date.now().toString() + "_" + uploadFile.name;

    const referenceRef = ref(storage, "references/" + filename);

    const uploadTask = uploadBytesResumable(referenceRef, uploadFile);

    const unsubscribe = uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // const progress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        // switch (snapshot.state) {
        //   case "paused":
        //     console.log("Upload is paused");
        //     break;
        //   case "running":
        //     console.log("Upload is running");
        //     break;
        // }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            setErrorUpload("Doesn't have permission to do this (unauthorize)");
            break;
          case "storage/canceled":
            // User canceled the upload
            setErrorUpload("Canceled the upload");
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            setErrorUpload(
              "Unknown error occurred, inspect error.serverResponse"
            );
            break;
        }
        // unsubscribe event
        unsubscribe();
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUploadFileName(filename);
          setUploadFileSize(uploadFile.size);
          setDownloadedUrl(downloadURL);
        });
        // unsubscribe event
        unsubscribe();
      }
    );
  };

  const deleteReference = async (target) => {
    const remaining = references.filter((el) => el.id !== target.id);
    setReferences(remaining);

    // delete file data from firestore
    await deleteDoc(doc(db, "references", target.id));

    // delete file data from storage
    const desertRef = ref(storage, "references/" + target.referenceName);
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  };

  // When ReCAPTCHA is successfully worked, this function is executed
  function onChange(value) {
    setVerified(true);
  }

  function alertTrigger() {
    alert(
      "To download the archive reference, please click the ReCAPTCHA below to pass the authentication."
    );
  }

  return (
    <React.Fragment>
      <div className="container mt-4 mt-md-5 constraint-container-size mb-5">
        {/*  upload modal */}
        <div
          className="modal fade"
          id="uploadModal"
          tabIndex="-1"
          aria-labelledby="uploadModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mt-5">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="uploadModalLabel">
                  Upload a Reference
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-secondary text-bold">
                <div className="mb-3">
                  Please fill in all fields with appropriate value.
                </div>
                <div className="mb-3">
                  <label htmlFor="referenceFile" className="form-label">
                    Reference File (pdf, pptx, xls and etc.)
                  </label>
                  <input
                    type="file"
                    id="file"
                    ref={fileRef}
                    className="form-control"
                    onChange={(e) => setUploadFile(e.target.files[0])}
                  />
                  <div id="resourceFile" className="form-text">
                    The file will be uploaded.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Reference Title
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Title"
                    value={uploadFileTitle}
                    onChange={(e) => setUploadFileTitle(e.target.value)}
                  />
                  <div id="" className="form-text">
                    <span className="text-danger">*</span> This field is
                    required.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Reference Type (format & extension)
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => setUploadFileType(e.target.value)}
                  >
                    <option value={"pdf"}>.pdf (PDF)</option>
                    <option value={"docx"}>.docx (Word)</option>
                    <option value={"xlsx"}>.xlsx (Excel)</option>
                    <option value={"pptx"}>.pptx (Power Point)</option>
                    <option value={"zip"}>.zip (Compress)</option>
                    <option value={"other"}>
                      other types (None of the above)
                    </option>
                  </select>

                  <div id="" className="form-text">
                    <span className="text-success">*</span> Select appropriate
                    file type.
                  </div>
                </div>
                <div className="mb-3">
                  <h5 className="mb-4">Example images of reference type</h5>
                  <div className="row">
                    <div className="col-2">
                      <img
                        src="/images/resources-type/type-pdf.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-2">
                      <img
                        src="/images/resources-type/type-docx.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-2">
                      <img
                        src="/images/resources-type/type-xlsx.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-2">
                      <img
                        src="/images/resources-type/type-pptx.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-2">
                      <img
                        src="/images/resources-type/type-zip.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-2">
                      <img
                        src="/images/resources-type/type-other.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => clearStateInput()}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary 
                  }`}
                  data-bs-dismiss="modal"
                  onClick={() => uploadFileHandler()}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* upload modal end */}

        <div className="d-flex align-items-center mb-3 pb-2">
          <div className="fs-1 me-5" style={{ color: "#0D3E62" }}>
            References
          </div>

          <div className="me-5">
            {userInfo && (
              <div>
                {!modifyMode ? (
                  <span className="" onClick={() => setModifyMode(!modifyMode)}>
                    <i className="fas fa-cog fs-1 icon-normal"></i>
                  </span>
                ) : (
                  <>
                    <span
                      className=""
                      aria-hidden="true"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadModal"
                    >
                      <i class="fas fa-file-upload fs-1 icon-primary"></i>
                    </span>
                    <span
                      className="ms-4"
                      onClick={() => setModifyMode(!modifyMode)}
                    >
                      <i class="fas fa-check fs-1 icon-complete"></i>
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
          {loadingUpload && <LoadingBox message="Uploading..." />}
        </div>
        {/* Note */}
        <div className="shadow-sm mb-5">
          <div className="alert alert-warning shadow-sm mb-4" role="alert">
            <h4 className="alert-heading mb-3">Note</h4>
            <p className="mb-2">
              To download the <strong>archive reference</strong>, please{" "}
              <strong>click</strong> the ReCAPTCHA below to pass the
              authentication.
            </p>
            <hr />
            <h5 className="alert-heading mb-3">Google ReCAPTCHA</h5>
            <ReCAPTCHA
              className=""
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onChange}
              onExpired={() => setVerified(false)}
            />
          </div>
        </div>
        {/* error message */}
        {errorUpload && <MessageBox variant="danger">{errorUpload}</MessageBox>}
        {/* resources should be rendered here */}
        <hr />
        <div className="">
          {references
            .slice()
            .sort((a, b) => a.date - b.date)
            .map((el, index) => (
              <div key={index} className="my-4 d-flex align-items-center">
                <div className="me-4">
                  {verified ? (
                    <a href={el.referenceUrl} download className="fs-6">
                      <h5>{el.referenceTitle}</h5>
                    </a>
                  ) : (
                    <>
                      <a href="#">
                        <h5 onClick={() => alertTrigger()}>
                          {el.referenceTitle}
                        </h5>
                      </a>
                    </>
                  )}

                  <small className="text-muted">
                    Created at : {el.date.toDate().toString().substr(4, 11)}
                  </small>
                  <small className="text-muted">
                    {" "}
                    | {(el.referenceSize / 1e6).toFixed(2) + " MB"}
                  </small>
                </div>
                <div>
                  {userInfo && modifyMode && (
                    <button
                      className="btn btn-danger"
                      // aria-hidden="true"
                      // data-bs-toggle="modal"
                      // data-bs-target="#deleteModal"
                      onClick={() => deleteReference(el)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReferenceScreen;
