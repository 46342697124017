import {
  ARTICLE_LIST_FAIL,
  ARTICLE_LIST_REQUEST,
  ARTICLE_LIST_SUCCESS,
} from "../constants/articleConstants";

import { db } from "../firebase/firebase.config";
import { collection, getDocs } from "firebase/firestore";

export const listArticles = () => async (dispatch) => {
  dispatch({ type: ARTICLE_LIST_REQUEST });
  try {
    const querySnapshot = await getDocs(collection(db, "articles"));

    let articles = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      articles.push({ id: doc.id, ...doc.data() });
    });
    articles.sort((a, b) => b.date - a.date);
    dispatch({ type: ARTICLE_LIST_SUCCESS, payload: articles });
  } catch (error) {
    dispatch({
      type: ARTICLE_LIST_FAIL,
      payload: error.message ? error.message : error,
    });
  }
};
