import React from "react";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Bearing from "../components/conveyingProducts/Bearing";
import ChainBeltReturnSystems from "../components/conveyingProducts/ChainBeltReturnSystem";
import Chains from "../components/conveyingProducts/Chains";
import ConveyingProducts from "../components/conveyingProducts/ConveyingProducts";
import CurvesTracks from "../components/conveyingProducts/CurvesTracks";
import FrameStructureSupports from "../components/conveyingProducts/FrameStructureSupports";
import Levelers from "../components/conveyingProducts/Levelers";
import MiscellaneousProducts from "../components/conveyingProducts/MiscellaneousProducts";
import ModSort from "../components/conveyingProducts/ModSort";
import ModularBelts from "../components/conveyingProducts/ModularBelts";
import SideGuideBracketsAcc from "../components/conveyingProducts/SideGuideBracketsAcc";
import SideGuideSolutions from "../components/conveyingProducts/SideGuideSolutions";
import WearStrips from "../components/conveyingProducts/WearStrips";
import ModFlex from "../components/conveyingProducts/ModFlex";

function ProductsScreen() {
  // For nested routing
  let { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <div className="container my-4 my-lg-5">
        <div className="row justify-content-evenly">
          <div className="col-3 me-3 d-none d-lg-block">
            <div className="side">
              <div className="shadow-sm p-4 rounded bg-white mb-5">
                <div className="img-box p-2 text-center">
                  <img
                    className="img-fluid mb-4"
                    src="/images/family-site-logo/system_plast_logo.png"
                    alt=""
                  />
                  <a href="https://www.systemplastsmartguide.com/">
                    <img
                      className="img-fluid"
                      src="/images/smartguide.gif"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="list">
                <ul>
                  <Link to={`${url}`}>
                    <li className="border-bottom border-1 py-2">
                      All The Products
                    </li>
                  </Link>
                  <Link to={`${url}/chains`}>
                    <li className="border-bottom border-1 py-2">Chains</li>
                  </Link>
                  <Link to={`${url}/modular-belts`}>
                    <li className="border-bottom border-1 py-2">
                      Modular Belts
                    </li>
                  </Link>
                  <Link to={`${url}/curves-tracks`}>
                    <li className="border-bottom border-1 py-2">
                      Curves & Tracks
                    </li>
                  </Link>
                  <Link to={`${url}/wear-strips`}>
                    <li className="border-bottom border-1 py-2">Wear Strips</li>
                  </Link>
                  <Link to={`${url}/chain-belt-return-systems`}>
                    <li className="border-bottom border-1 py-2">
                      Chain & Belt Return System
                    </li>
                  </Link>
                  <Link to={`${url}/frame-structure-supports`}>
                    <li className="border-bottom border-1 py-2">
                      Frame & Structure Supports
                    </li>
                  </Link>
                  <Link to={`${url}/side-guide-brackets-accessories`}>
                    <li className="border-bottom border-1 py-2">
                      Side Guide Brackets & Accessories
                    </li>
                  </Link>
                  <Link to={`${url}/side-guide-solutions`}>
                    <li className="border-bottom border-1 py-2">
                      Side Guide Solutions
                    </li>
                  </Link>
                  <Link to={`${url}/levelers`}>
                    <li className="border-bottom border-1 py-2">Levelers</li>
                  </Link>
                  <Link to={`${url}/miscellaneous-products`}>
                    <li className="border-bottom border-1 py-2">
                      Miscellaneous Products
                    </li>
                  </Link>
                  <Link to={`${url}/bearings`}>
                    <li className="border-bottom border-1 py-2">Bearing</li>
                  </Link>
                  <Link to={`${url}/modSort`}>
                    <li className="border-bottom border-1 py-2">ModSort</li>
                  </Link>
                  <Link to={`${url}/modFlex`}>
                    <li className="border-bottom border-1 py-2">
                      ModFlex - Modular Curve
                    </li>
                  </Link>

                  <a href="https://www.systemplastsmartguide.com/int/Smart-Guide/72010A/">
                    <li className="border-bottom border-1 py-2">
                      Engineering Manual{" "}
                      <i className="fas fa-external-link-alt"></i>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="main-section">
              <BreadCrumb pathname={pathname} />
              <Switch>
                {/* Viewing all the products thumbnail & desc */}
                <Route exact path={path} component={ConveyingProducts} />
                {/* Below is the details info */}
                <Route exact path={`${path}/chains/:id?`} component={Chains} />

                <Route
                  exact
                  path={`${path}/modular-belts/:id?`}
                  component={ModularBelts}
                />
                <Route
                  exact
                  path={`${path}/modSort/:id?`}
                  component={ModSort}
                />
                <Route
                  exact
                  path={`${path}/modFlex/:id?`}
                  component={ModFlex}
                />
                <Route
                  exact
                  path={`${path}/curves-tracks/:id?`}
                  component={CurvesTracks}
                />
                <Route
                  exact
                  path={`${path}/wear-strips/:id?`}
                  component={WearStrips}
                />
                <Route
                  exact
                  path={`${path}/chain-belt-return-systems/:id?`}
                  component={ChainBeltReturnSystems}
                />
                <Route
                  exact
                  path={`${path}/frame-structure-supports/:id?`}
                  component={FrameStructureSupports}
                />
                <Route
                  exact
                  path={`${path}/side-guide-brackets-accessories/:id?`}
                  component={SideGuideBracketsAcc}
                />
                <Route
                  exact
                  path={`${path}/side-guide-solutions/:id?`}
                  component={SideGuideSolutions}
                />
                <Route
                  exact
                  path={`${path}/levelers/:id?`}
                  component={Levelers}
                />
                <Route
                  exact
                  path={`${path}/miscellaneous-products/:id?`}
                  component={MiscellaneousProducts}
                />
                <Route
                  exact
                  path={`${path}/bearings/:id?`}
                  component={Bearing}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductsScreen;
