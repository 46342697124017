import React from "react";

function BackgroundImageHeader() {
  return (
    <div className="contact-header-container">
      <div
        className="contact-header-img"
        style={{
          backgroundImage: `url(
              "https://images.unsplash.com/photo-1448697138198-9aa6d0d84bf4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80"
            )`,
        }}
      ></div>
      <div className="contact-header-typography">
        <h1 className="mb-3">Contact Information</h1>
        {/* <p className="fs-5 fw-light d-none d-md-block">
          Whether you have a question about features, trials, pricing, need a
          demo, or anything else, Our team is ready to answer all your questions
        </p> */}
        {/* <p className="fs-6 fw-light d-md-none mt-4">
          Our team is ready to answer all your questions. <br />
          <br /> Contact Us!
        </p> */}
      </div>
    </div>
  );
}

export default BackgroundImageHeader;
