import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import SmartguideBtn from "../SmartguideBtn";

const list = [
  {
    title: "ModFlex Modular Curve",
    link: "https://www.systemplastsmartguide.com/int/Smart-Guide/57030A/",
    img: "/images/modFlex/0-modflex-modular-curve.png",
    id: "modflex-modular-curve",
  },
];

function ModFlex() {
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    // else scroll to id
    // else {
    //   const id = hash.replace("#", "");
    //   const element = document.getElementById(id);
    //   setTimeout(() => {
    //     if (element) {
    //       element.scrollIntoView();
    //     }
    //   }, 100);
    // }
  }, [pathname]); // do this on route change

  return (
    <React.Fragment>
      {id === undefined ? (
        <React.Fragment>
          <div className="mb-5">
            <h2 className="text-secondary fw-bold mb-4">
              ModFlex - Modular Curve
            </h2>
            <p className="text-secondary mb-5">
              The new modular curve is a compact and safe unit that will
              carefully handle packs, trays, envelopes, bags, six-packs, boxes
              in all kind of shapes and sizes around corners that can vary
              between 30 and 180 degrees. The ModFlex unit improves the
              reliability of your system as it avoids obstructions by its
              perfect product support.
            </p>

            {/* what if section */}
            <div className="row align-items-center mb-5">
              <div className="mb-4 col-10 col-md-6">
                <h2>
                  <span style={{ color: "#007ec3", fontSize: "3rem" }}>
                    {" "}
                    What if
                  </span>{" "}
                  <br />
                  modular curve could safely and easily handle items of various
                  shapes at various angles ?
                </h2>
              </div>
              <div className="col-9 col-md-6">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="/images/modFlex/modflex-main.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* what it does?  */}
            <div className="my-5">
              <h2 className="mb-4">What it Does ?</h2>
              <div className="row">
                <div className="col-8 col-sm-8">
                  <ul>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>
                        Conveying mass and heavy products safely and easily
                      </div>
                    </li>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>
                        Handles various types of items in curved corners
                      </div>
                    </li>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>Corners can vary between 30 and 180 degrees</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Features and Benefits */}
            <div className="my-5">
              <h2 className="mb-4">ModFlex Features and Application</h2>
              <div className="row">
                <div className="col-8 col-sm-6">
                  <ul>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>
                        Suitable for conveying heavy products and mass products
                        handling
                      </div>
                    </li>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>Safe design (completely closed structure)</div>
                    </li>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div> High chemical and wear resistance</div>
                    </li>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>Low noise and dry solution</div>
                    </li>
                  </ul>
                </div>
                <div className="col-8 col-sm-6">
                  <ul>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>Logistics industry and distribution centers</div>
                    </li>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>Food and beverage</div>
                    </li>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>Tiles, bricks and cement production</div>
                    </li>
                    <li className="d-flex mb-3">
                      <div className="me-2">•</div>
                      <div>
                        Hostile environments and heavy duty operation in general
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* apply */}
            <div className="img-box mb-5">
              <img
                className="img-fluid"
                src="/images/modFlex/modflex-item-types.png"
                alt="modSort"
              />
            </div>
            {/* details */}
            {/* How it Works? */}
            <div className="img-box mb-5">
              <img
                className="img-fluid"
                src="/images/modFlex/modflex-details.png"
                alt="modSort"
              />
            </div>
          </div>
          {/* types of */}
          <div className="my-4">
            <h4 className="text-secondary border-bottom border-2 border-secondary pb-2 mb-4">
              INDEX
            </h4>
            <ul className="">
              {list.map((item, index) => (
                <li key={index} className="fs-6 mb-2">
                  <Link className="py-2 d-block" to={`${pathname}/${item.id}`}>
                    • {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </React.Fragment>
      ) : (
        <ul>
          {list
            .filter((item) => item.id === id)
            .map((item, index) => (
              <li id={item.id} key={index} className="bg-white py-2 mb-5">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <h3 className="text-secondary fw-bold mb-3 mb-md-4">
                    {item.title}
                  </h3>
                </div>
                <div className="img-box">
                  <img className="img-fluid" src={item.img} alt="" />
                </div>
                <SmartguideBtn url={item.link} />
              </li>
            ))}
        </ul>
      )}
    </React.Fragment>
  );
}

export default ModFlex;
