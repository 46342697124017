import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Link, Redirect, Switch } from "react-router-dom";
import { signout } from "./action/userActions";
import HomeScreen from "./screens/HomeScreen";
import SigninScreen from "./screens/SigninScreen";
import RegisterScreen from "./screens/RegisterScreen";
import Footer from "./components/Footer";
import PageNotFoundScreen from "./screens/PageNotFoundScreen";
import OffCanvas from "./components/OffCanvas";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import VideoScreen from "./screens/VideoScreen";
import ProductsScreen from "./screens/ProductsScreen";
import GalleryScreen from "./screens/GalleryScreen";
import ArticlesScreen from "./screens/ArticlesScreen";
import ArticleScreen from "./screens/ArticleScreen";
import CreateArticleScreen from "./screens/CreateArticleScreen";
import EditArticleScreen from "./screens/EditArticleScreen";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ContactInformationScreen from "./screens/ContactInformationScreen";
import PhotoLibraryOutlinedIcon from "@material-ui/icons/PhotoLibraryOutlined";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
} from "./constants/userConstants";
import AboutUs from "./screens/AboutUs";
import ProductsHoverBox from "./components/ProductsHoverBox";
import ReferenceScreen from "./screens/ReferenceScreen";

function App() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading } = userSignin;

  const dispatch = useDispatch();

  const signoutHandler = () => {
    dispatch(signout());
  };

  useEffect(() => {
    const auth = getAuth();
    dispatch({ type: USER_SIGNIN_REQUEST });
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        // to persist redux store sign in state when browser is refreshed
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: user });
      } else {
        // User is signed out
        dispatch({ type: USER_SIGNOUT });
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // // to clear hover menu entirely when link clicked
  const [clearHover, setClearHover] = useState(false);

  // const closeMenu = () => {
  //   setClearHover(true);
  // };

  React.useEffect(() => {
    let timeout;
    if (clearHover) {
      timeout = setTimeout(() => {
        setClearHover(false);
      }, 0); // Adjust this timeout to suit the applications' needs
    }
    return () => clearTimeout(timeout);
  }, [clearHover]);

  // const EnhancedLink = ({ to, children }) => (
  //   <Link to={to} onClick={closeMenu}>
  //     {children}
  //   </Link>
  // );

  return (
    <BrowserRouter>
      {/* Nav */}
      <nav
        className="navbar navbar-expand-lg navbar-light mb-0 pb-lg-3 shadow-sm"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="container-lg non-flex">
          {/* nav brand */}
          <Link className="navbar-brand order-2 d-lg-none me-auto" to="/">
            <img
              style={{ width: 190, paddingBottom: 3 }}
              className="brand_logo"
              src="/images/saehan-logo/saehan_text_1.png"
              alt=""
            />
          </Link>
          {/* nav mobile toggle btn */}
          <button
            className="navbar-toggler border-0 order-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            // data-bs-toggle="collapse"
            // data-bs-target="#navbarSupportedContent"
            // aria-controls="navbarSupportedContent"
            // aria-expanded="false"
            // aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* nav offcanvas content mobile */}
          <OffCanvas />

          {/* nav content (lg ->) */}
          <div
            className="fw-light d-none d-lg-block flex-fill pt-4"
            id="navbarSupportedContent"
          >
            <div className="row row-cols-3 justify-content-between mb-1">
              <div className="col-3">
                <ul className="d-flex gap-4">
                  <li>
                    <small>
                      <Link to="/contact-information">
                        <HelpOutlineOutlinedIcon className="fs-3" /> Contact
                        Info
                      </Link>
                    </small>
                  </li>

                  <li>
                    <small>
                      <Link to="/video">
                        <YouTubeIcon className="fs-3" /> Video
                      </Link>
                    </small>
                  </li>
                </ul>
              </div>
              <div className="col-5 text-center">
                {/* nav brand */}
                <Link className="navbar-brand ms-auto" to="/">
                  <img
                    style={{ width: 70 }}
                    className="brand_logo me-4"
                    src="/images/saehan-logo/saehan_logo.png"
                    alt=""
                  />
                  <img
                    style={{ width: 270 }}
                    className="brand_logo pb-2 "
                    src="/images/saehan-logo/saehan_text_1.png"
                    alt=""
                  />
                </Link>
              </div>
              <div className="col-3">
                <ul className="d-flex gap-4 justify-content-end">
                  {loading ? (
                    <div
                      className="spinner-border text-secondary"
                      role="status"
                    ></div>
                  ) : userInfo ? (
                    <li className="text-secondary hover-menu-mini">
                      <small>
                        <AccountCircleOutlinedIcon className="fs-3" />{" "}
                        {userInfo.displayName}
                      </small>

                      <div className="hover-box-mini py-3 px-4 shadow">
                        <Link
                          className="btn btn-dark"
                          to="/"
                          onClick={signoutHandler}
                        >
                          Sign Out
                        </Link>
                      </div>
                    </li>
                  ) : (
                    <li>
                      <small>
                        <Link to="/signin">
                          <AccountCircleOutlinedIcon className="fs-3" /> Sign in
                        </Link>
                      </small>
                    </li>
                  )}

                  <li>
                    <small>
                      <Link to="/gallery">
                        <PhotoLibraryOutlinedIcon className="fs-3" /> Gallery
                      </Link>
                    </small>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row pb-3 pt-4 px-5">
              <ul
                style={{ maxWidth: 630 }}
                className="d-flex justify-content-between mx-auto"
              >
                <li className="" style={{ fontSize: "17px" }}>
                  <Link className="px-2" to="/">
                    Home
                  </Link>
                </li>
                <li className={`hover-menu ${clearHover ? "clearHover" : ""}`}>
                  <Link
                    className="px-2"
                    to="/products"
                    style={{ fontSize: "17px" }}
                  >
                    Products
                  </Link>
                  {/* products nav hover box */}
                  <ProductsHoverBox setClearHover={setClearHover} />
                </li>
                {/* <li className="px-2" style={{ fontSize: "17px" }}>
                  <a href="https://www.systemplastsmartguide.com/int/Smart-Guide/10110A/">
                    Smart Guide
                  </a>
                </li> */}
                <li className="px-2" style={{ fontSize: "17px" }}>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li className="px-2" style={{ fontSize: "17px" }}>
                  <Link className="" to="/articles?page=1">
                    Articles
                  </Link>
                </li>
                <li className="px-2" style={{ fontSize: "17px" }}>
                  <Link className="" to="/references">
                    References
                  </Link>
                </li>
                <li
                  className="hover-menu-mini px-2"
                  style={{ fontSize: "18px" }}
                >
                  <Link>Partners</Link>

                  <div className="hover-box-mini py-3 px-3 shadow border">
                    <h6 className="text-secondary">
                      Partners <i className="fas fa-external-link-alt"></i>
                    </h6>

                    <div className="border-bottom border-1 mb-2 bg-second"></div>

                    <a className="" href="https://www.regalrexnord.com/">
                      Regal Rexnord
                    </a>
                    <a
                      className=""
                      href="https://www.regalbeloit.com/brands/System-Plast"
                    >
                      System Plast
                    </a>
                    {/* <a className="" href="https://www.rexnord.com/home.aspx">
                      Rexnord
                    </a> */}
                    <a className="" href="https://www.makitech.co.jp/en/">
                      Makitech
                    </a>
                  </div>
                </li>

                {/* <li className="">
                  <Link className="cart" to="/cart">
                    <ShoppingCartIcon style={{ fontSize: 28 }} />

                    {cartItems.length > 0 && (
                      <span className="badge">
                        <span>{cartItems.length}</span>
                      </span>
                    )}
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* Router */}
      <main>
        <Switch>
          <Route path="/signin" component={SigninScreen}></Route>
          <Route path="/products" component={ProductsScreen}></Route>
          <Route path="/register" component={RegisterScreen}></Route>
          <Route path="/video" component={VideoScreen}></Route>
          <Route path="/gallery" component={GalleryScreen}></Route>
          <Route path="/about-us" component={AboutUs}></Route>
          <Route path="/references" component={ReferenceScreen}></Route>

          {/* Article Route */}
          <Route path="/articles" exact component={ArticlesScreen}></Route>
          <Route path="/articles/:id" exact component={ArticleScreen}></Route>
          <Route
            path="/create-article"
            exact
            render={(props) =>
              userInfo ? (
                <CreateArticleScreen {...props}></CreateArticleScreen>
              ) : (
                <Redirect to="/"></Redirect>
              )
            }
          ></Route>
          <Route
            path="/articles/:id/edit"
            exact
            render={(props) =>
              userInfo ? (
                <EditArticleScreen {...props}></EditArticleScreen>
              ) : (
                <Redirect to="/"></Redirect>
              )
            }
          ></Route>

          <Route
            path="/contact-information"
            component={ContactInformationScreen}
          ></Route>

          <Route path="/" component={HomeScreen} exact></Route>
          <Route path="*" component={PageNotFoundScreen} exact></Route>
        </Switch>
      </main>

      {/* Footer Component */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
