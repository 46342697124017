import React from "react";
import { Link } from "react-router-dom";
import conveyingProductsList from "./conveyingProductsList";

function ConveyingProducts() {
  return (
    <React.Fragment>
      <div className="img-box mb-4">
        <img
          className="img-fluid rounded"
          src="/images/banner_img1.png"
          alt=""
        />
      </div>
      <h2 className="text-secondary fw-bold mb-4">
        System Plast® Conveying Systems
      </h2>
      <p className="text-secondary border-bottom border-1 pb-5 mb-5">
        The System Plast® brand is a global leader in high quality plastic
        conveyor chains, modular plastic belts, and industry-leading conveying
        components. They also have manufacturing and service centers located
        strategically around the world.
        <br />
        <br />
        System Plast® brand is focused with a drive towards assisting customers
        achieve sustainability while lowering their total cost of ownership.
        They do this through continuous improvement activities. System Plast®
        brand has a culture of innovation. Power Transmission Solutions™
        continues support for this with strong technical capabilities and
        resources. This has been accomplished by providing conveying components
        solutions for diverse material handling applications.
      </p>

      <div className="list">
        <ul>
          {conveyingProductsList.map((product, index) => (
            <li key={index} className="my-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-6 col-sm-4 col-xl-3 mb-3">
                  <div className="img-box mb-2 mb-sm-0">
                    <img
                      className="img-fluid"
                      src={product.img}
                      alt=""
                      style={{ width: 150 }}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Link to={product.link}>
                    <h5>{product.title}</h5>
                  </Link>
                  <p>{product.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
}

export default ConveyingProducts;
