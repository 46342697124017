import React from "react";
import { Link } from "react-router-dom";
import conveyingProductsList from "./conveyingProducts/conveyingProductsList";

function ProductsQuickMenu() {
  return (
    <>
      <h4 className="mb-1 fs-5 text-secondary">Products Quick Menu</h4>
      <div className="row row-cols-3">
        {conveyingProductsList.map((item, index) => (
          <Link
            key={index}
            className="col p-3"
            to={item.link}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <img
              style={{ width: "3rem" }}
              className="img-fluid mb-2"
              src={item.img}
              alt=""
            />
            <small className="d-block">{item.shortTitle || item.title}</small>
          </Link>
        ))}
      </div>
    </>
  );
}

export default ProductsQuickMenu;
