import React from "react";

function SmartguideBtn({ url }) {
  return (
    <div className="mt-5 w-sm-50">
      <a className="d-inline-block smartguide-btn fs-5 fw-bold" href={url}>
        <div className="d-flex align-items-center">
          <span className="front me-1">Smart</span>
          <span className="middle">Guide</span>
          <i class="fas fa-angle-right ms-2 back fs-3"></i>
        </div>
      </a>
    </div>
  );
}

export default SmartguideBtn;
