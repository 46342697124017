import React from "react";
import { Link } from "react-router-dom";

function BreadCrumb({ pathname }) {
  let crumb = pathname.split("/");

  let acc = "";
  let crumbList = crumb.map((item) => {
    if (item === "") {
      return {
        url: "/",
        title: (
          <span>
            <i class="fas fa-home"></i> home
          </span>
        ),
      };
    } else {
      acc += "/" + item;
      item = item.replace(/-/g, " ");
      return { url: acc, title: item };
    }
  });

  return (
    <div className="pt-1 pb-4">
      <nav aria-label="breadcrumb ">
        <ul className="d-flex overflow-x-scroll overflow-scroll">
          {crumbList.map((item, index, { length }) => {
            if (index + 1 === length) {
              return (
                <li
                  key={index}
                  className="text-secondary bread-crumb-active text-nowrap bread-crumb-text"
                >
                  {item.title}
                </li>
              );
            } else {
              return (
                <li
                  key={index}
                  className="text-secondary text-nowrap bread-crumb-text"
                >
                  <Link className="py-2" to={item.url}>
                    {item.title}
                  </Link>
                  <small>
                    <i className="fas fa-chevron-right px-2"></i>
                  </small>
                </li>
              );
            }
          })}
        </ul>
      </nav>
    </div>
  );
}

export default BreadCrumb;
