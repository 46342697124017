import React from "react";

function PageNotFoundScreen(props) {
  const browseNow = () => {
    props.history.push("/");
  };

  return (
    <div className="container my-5 p-5 text-center">
      <div className="mb-4">
        <h1 className="">Page Not Found</h1>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center mb-5">
        {/* <h6 className="fs-5 mb-3">Page Not Found ;)</h6> */}
        <p className="text-secondary fw-light mb-5">
          The page you're looking for is currently unavailable.
        </p>
        <button className="btn btn-outline-dark btn-lg" onClick={browseNow}>
          Go to Home
        </button>
      </div>
    </div>
  );
}

export default PageNotFoundScreen;
