import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <React.Fragment>
      <footer className="mt-auto pt-5 pb-4 fw-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-4 gy-4 mb-5">
            <div className="col pb-5">
              <ul>
                <li className="">
                  <img
                    className="brand_logo"
                    src="/images/saehan-logo/saehan_logo.png"
                    alt=""
                    style={{ width: 150 }}
                  />
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="d-grid gap-1">
                <h5>Company Info</h5>
                <li className="text-secondary">
                  <Link to="/">Home</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/about-us">About Us</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/contact-information">Contact Information</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/video">Stories & Videos</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/articles?page=1">Articles</Link>
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="d-grid gap-1">
                <h5>Products</h5>
                <li className="text-secondary">
                  <Link to="/products/chains">Chains</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/products/modular-belts">Modular Belts</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/products/modSort">ModSort</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/products/modFlex">ModFlex</Link>
                </li>
                <li className="text-secondary">
                  <Link to="/products/curves-tracks">Curves & Tracks</Link>
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="d-grid gap-1">
                <h5>External Link</h5>
                <li className="text-secondary">
                  <a href="https://www.systemplastsmartguide.com/int/Smart-Guide/10110A/">
                    Smart Guide
                  </a>
                </li>
                <li className="text-secondary">
                  <a className="" href="https://www.regalrexnord.com/">
                    Regal Rexnord
                  </a>
                </li>
                <li className="text-secondary">
                  <a href="https://www.regalbeloit.com/brands/System-Plast">
                    System Plast
                  </a>
                </li>
                {/* <li className="text-secondary">
                  <a className="" href="https://www.rexnord.com/home.aspx">
                    Rexnord
                  </a>
                </li> */}
                <li className="text-secondary">
                  <a className="" href="https://www.makitech.co.jp/en/">
                    Makitech
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row text-secondary" style={{ fontSize: 13 }}>
            <div className="col">
              <p>All Kind of Conveyor Components</p>
              <p>
                A company pursuing a sustainable future with the highest quality
                conveyor parts.
              </p>
              <p>© 2021 SAEHAN SYSTEM. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
