import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { signin, signinWithGoogle } from "../action/userActions";
import MessageBox from "../components/MessageBox";
import { USER_SIGNIN_ERROR_RESET } from "../constants/userConstants";

function SigninScreen(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  };

  const googleLoginHandler = () => {
    dispatch(signinWithGoogle());
  };

  useEffect(() => {
    dispatch({ type: USER_SIGNIN_ERROR_RESET });
    if (userInfo) {
      props.history.push(redirect);
    }
  }, [userInfo]);

  return (
    <div className="container d-flex justify-content-center py-5 my-5">
      <form onSubmit={submitHandler}>
        <h1 className="mb-4">Sign In</h1>
        {error && <MessageBox variant="danger">{error}</MessageBox>}
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            placeholder="example@email.com"
            id="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            aria-describedby="emailHelp"
          />
          <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Enter Password
          </label>
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            id="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="form-text">8-20 Characters</div>
        </div>
        <div className="mb-4 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            Check me out
          </label>
        </div>
        {loading ? (
          <div className="d-grid mb-4">
            <button className="btn btn-outline-dark" type="button">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class=""> Loading...</span>
            </button>
          </div>
        ) : (
          <div className="d-grid mb-4">
            <button type="submit" className="btn btn-outline-dark ">
              Sign In
            </button>
          </div>
        )}
        <div className="mb-5">
          New to Here?{" "}
          <Link
            className="text-decoration-underline"
            to={`/register?redirect=${redirect}`}
          >
            Create your account
          </Link>
        </div>
        <div className="text-left mb-3 fs-5">Sign In with Other Services</div>
        <div className="d-grid mb-4">
          <button
            type="button"
            className="btn btn-outline-dark disabled"
            onClick={() => googleLoginHandler()}
          >
            <div className="">
              <i className="fab fa-google">
                <small> Sign In with Google</small>
              </i>
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}

export default SigninScreen;
