import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signout } from "../action/userActions";
import { useHistory } from "react-router";
import ProductsQuickMenu from "./ProductsQuickMenu";

function OffCanvas() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const dispatch = useDispatch();

  const history = useHistory();

  const signoutHandler = () => {
    dispatch(signout());
    history.push("/");
  };

  return (
    <div
      className="offcanvas offcanvas-start fw-light"
      tabIndex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header mt-2 mb-4">
        <div className="d-flex align-items-center ps-2 fw-light">
          <img
            className="brand_logo me-3 mt-1"
            style={{ width: 50 }}
            src="/images/saehan-logo/saehan_logo.png"
            alt=""
          />
          <span className="fs-3 fw-light">SAEHAN</span>
        </div>

        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="overflow-scroll fs-6 ps-4 pe-4 pb-5">
        <ul className="d-grid gap-4">
          <li className="">
            <Link
              className=""
              to="/"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Home
            </Link>
          </li>
          <li className="">
            <Link
              className=""
              to="/products"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Products
            </Link>
          </li>
          <li className="">
            <Link
              className=""
              to="/products/modSort"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              ModSort
            </Link>
          </li>
          <li className="">
            <Link
              className=""
              to="/products/modFlex"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              ModFlex
            </Link>
          </li>
          <li className="">
            <Link
              className=""
              to="/articles?page=1"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Articles
            </Link>
          </li>
          <li className="">
            <Link
              className=""
              to="/about-us"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              About Us
            </Link>
          </li>
          <li className="">
            <Link
              className=""
              to="/contact-information"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Contact Information
            </Link>
          </li>
          <li className="">
            <Link
              className=""
              to="/gallery"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Photo Gallery
            </Link>
          </li>
          <li className="">
            <Link
              className=""
              to="/video"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Stories & Videos
            </Link>
          </li>
          <li className="">
            <Link
              className="dropdown-toggle collapsed"
              href="#"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#partners-collapse"
              aria-expanded="false"
            >
              Partners
            </Link>
            <ul id="partners-collapse" className="collapse fs-6 ps-3">
              <li className="my-3">
                <a
                  className=""
                  href="https://www.regalbeloit.com/"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  Regal Rexnord
                </a>
              </li>
              <li className="mb-3">
                <a
                  className=""
                  href="https://www.regalbeloit.com/brands/System-Plast"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  System Plast
                </a>
              </li>
              {/* <li className="mb-3">
                <a
                  className=""
                  href="https://www.rexnord.com/home.aspx"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  Rexnord
                </a>
              </li> */}
              <li className="">
                <a
                  className=""
                  href="https://www.makitech.co.jp/en/"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  Makitech
                </a>
              </li>
            </ul>
          </li>
          <li className="">
            <a href="https://www.systemplastsmartguide.com/int/Smart-Guide/10110A/">
              Smart Guide <i className="fas fa-external-link-alt"></i>
            </a>
          </li>

          <hr />

          <ProductsQuickMenu />

          <hr />

          {userInfo ? (
            <li className="mb-5">
              <Link
                className="dropdown-toggle collapsed"
                href="#"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#userIno-collapse"
                aria-expanded="false"
              >
                Signed as {userInfo.displayName}
              </Link>
              <ul id="userIno-collapse" className="collapse fs-6 ps-3 my-2">
                <li>
                  <Link
                    className=""
                    to="#"
                    onClick={signoutHandler}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Sign Out
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            <>
              <li className="">
                <Link
                  className=""
                  to="/signin"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  Sign In
                </Link>
              </li>
              <li className="">
                <Link
                  className=""
                  to="/register"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  Register
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default OffCanvas;
