import React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

function Video({ video, modifyMode, setDeletedId }) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  return (
    <div className="col mb-5 rounded px-3 position-relative">
      {userInfo && modifyMode && (
        <i
          className="fas fa-times-circle delete-badge d-block"
          aria-hidden="true"
          data-bs-toggle="modal"
          data-bs-target="#deleteModal"
          onClick={() => setDeletedId(video.id)}
        ></i>
      )}
      <div className="player-wrapper">
        <ReactPlayer
          url={video.videoUrl}
          className="react-player"
          controls
          width="100%"
          height="100%"
          config={{
            youtube: { playerVars: { origin: "https://www.youtube.com" } },
          }}
        />
      </div>
      <div className="typo-wrapper py-3">
        <h3 className="mb-3">{video.videoTitle}</h3>
        <p className="text-secondary">{video.videoDescription}</p>
      </div>
    </div>
  );
}

export default Video;
