import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useSelector } from "react-redux";

function Pictures({ galleryPic, setSelectedImg, deletePicture, modifyMode }) {
  const [deletePic, setDeletePic] = useState(null);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading } = userSignin;

  return (
    <div className="row row-cols-2 row-cols-lg-3">
      {/* <!-- Delete Modal --> */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-danger" id="deleteModalLabel">
                Delete Photo
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-secondary text-bold">
              This is an irreversible behavior. Are you sure to delete this?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => deletePicture(deletePic)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      {galleryPic.map((pic) => (
        <motion.div key={pic.id} className="col position-relative" layout>
          <>
            {userInfo && modifyMode && (
              <i
                className="fas fa-times-circle delete-badge d-block"
                aria-hidden="true"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                onClick={() => setDeletePic(pic)}
              ></i>
              // <span
              //   class="delete-badge d-block"
              //   data-bs-toggle="modal"
              //   data-bs-target="#deleteModal"
              //   onClick={() => setDeletePic(pic)}
              // ></span>
            )}

            <motion.div
              className="pic-box"
              onClick={() => setSelectedImg(pic.imgUrl)}
              whileHover={{ opacity: 1 }}
            >
              <motion.img
                className="pic"
                src={pic.imgUrl}
                alt="uploaded pic"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              />
            </motion.div>
          </>
        </motion.div>
      ))}
    </div>
  );
}

export default Pictures;
