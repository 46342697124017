import React, { useState } from "react";
import { Link } from "react-router-dom";

function ProductsHoverBox({ setClearHover }) {
  // to clear hover menu entirely when link clicked

  const closeMenu = () => {
    setClearHover(true);
  };

  const EnhancedLink = ({ to, children }) => (
    <Link to={to} onClick={closeMenu}>
      {children}
    </Link>
  );
  return (
    <div className={`hover-box border-bottom border-dark border-2`}>
      <div className="row hover-content mx-auto">
        <div className="col">
          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#4DBAEA" }}
            >
              Chains
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/chains">View All</EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/chains/metal-chains">
                  Metal Chains
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/chains/straight-running-thermoplastic-chains">
                  Straight Thermoplastic Chains
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/chains/side-flexing-thermoplastic-chains">
                  Flexing Thermoplastic Chains
                </EnhancedLink>
              </li>
            </ul>
          </div>
          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#e2ec55" }}
            >
              Modular Belts
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/modular-belts">
                  View All
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/modular-belts/straight-modular-belts">
                  Straight Modular Belts
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/modular-belts/side-flexing-modular-belts">
                  Side Flexing Modular Belts
                </EnhancedLink>
              </li>
            </ul>
          </div>
          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#e0b0d7" }}
            >
              Curves & Tracks
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/curves-tracks">
                  View All
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/curves-tracks/introduction-to-curves">
                  Introduction to Curves
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/curves-tracks/index-magnetic-curves">
                  Index Magnetic Curves
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/curves-tracks/index-curves-and-straight-tracks">
                  Index Curves & Straight Tracks
                </EnhancedLink>
              </li>
            </ul>
          </div>
          <div className="">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#F59F32" }}
            >
              Wear Strips
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/wear-strips">View All</EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/wear-strips/index-wear-strips">
                  Index Wear Strips
                </EnhancedLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#A276B0" }}
            >
              Return System
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/chain-belt-return-systems">
                  View All
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/chain-belt-return-systems/index-chain-belt-rollers-systems">
                  Index Chain & Belt Rollers Systems
                </EnhancedLink>
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#13AE8D" }}
            >
              Frame & Structure Supports
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/frame-structure-supports">
                  View All
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/frame-structure-supports/index-frame-structure-supports">
                  Index Frame & Structure Supports
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/frame-structure-supports/side-mounting-top-brackets">
                  Side Mounting Top Brackets
                </EnhancedLink>
              </li>

              <li>
                <EnhancedLink to="/products/frame-structure-supports/support-bases">
                  Support Bases
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/frame-structure-supports/support-heads">
                  Support Heads
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/frame-structure-supports/connecting-joints">
                  Connecting Joints
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/frame-structure-supports/side-mounting-top-brackets-with-expansion-plug">
                  Side Mounting Top Brackets with Expansion Plug
                </EnhancedLink>
              </li>
            </ul>
          </div>
          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#8DC255" }}
            >
              Side Guide Solutions
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/side-guide-solutions">
                  View All
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/side-guide-solutions/index-side-guide-clamps">
                  Index Side Guides & Clamps
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/side-guide-solutions/index-roller-side-guides-roller-transfer-plates">
                  Index Roller Side Guides & Roller Transfer Plates
                </EnhancedLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="col">
          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#FFD000" }}
            >
              Miscellaneous Products
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/miscellaneous-products">
                  View All
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/miscellaneous-products/index-miscellaneous-products">
                  Index Miscellaneous Products
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/miscellaneous-products/tube-joints-door-stop">
                  Tube Joints & Door Stop
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/miscellaneous-products/panel-clamps-end-plugs">
                  Panel Clamps & End Plugs
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/miscellaneous-products/panel-mounting-profiles">
                  Panel Mounting Profiles
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/miscellaneous-products/hinges">
                  Hinges
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/miscellaneous-products/door-handles">
                  Door Handles
                </EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/miscellaneous-products/door-locks">
                  Door Locks
                </EnhancedLink>
              </li>
            </ul>
          </div>
          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#e0656f" }}
            >
              Levelers
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/levelers">View All</EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/levelers/index-levelers-tube-ends">
                  Index Levelers & Tube Ends
                </EnhancedLink>
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#f8bade" }}
            >
              Bearing
            </h6>
            <ul className="d-grid gap-2">
              <li>
                <EnhancedLink to="/products/bearings">View All</EnhancedLink>
              </li>
              <li>
                <EnhancedLink to="/products/bearings/index-mounted-bearings">
                  Index Mounted Bearings
                </EnhancedLink>
              </li>
            </ul>
          </div>
          <div className="">
            <h6
              className="mb-3 hover-box-title-border"
              style={{ borderColor: "#B2B2B2" }}
            >
              Engineering Manuel
            </h6>
            <ul>
              <li>
                <a href="https://www.systemplastsmartguide.com/int/Smart-Guide/72010A/">
                  Guide Link
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row hover-content mx-auto mt-5">
        <div className="col">
          <h6
            className="mb-2 hover-box-title-border"
            style={{ borderColor: "#277cdd" }}
          >
            ModSort
          </h6>
          <ul className="d-grid gap-1">
            <li>
              <EnhancedLink to="/products/modSort">
                Index of ModSort
              </EnhancedLink>
            </li>
            <li>
              <EnhancedLink to="/products/modSort/right-angle-transfer-module">
                Right Angle Transfer Module
              </EnhancedLink>
            </li>
          </ul>
        </div>
        <div className="col">
          <h6
            className="mb-2 hover-box-title-border"
            style={{ borderColor: "#32cec6" }}
          >
            ModFlex
          </h6>
          <ul className="d-grid gap-1">
            <li>
              <EnhancedLink to="/products/modFlex">
                Index of ModFlex
              </EnhancedLink>
            </li>
            <li>
              <EnhancedLink to="/products/modFlex/modflex-modular-curve">
                ModFlex Modular Curve
              </EnhancedLink>
            </li>
          </ul>
        </div>
        <div className="col">
          {/* <h6 className="mb-2">System Plast® Products</h6>
                        <li>
                          <EnhancedLink to="/products">
                            View All Products
                          </EnhancedLink>
                        </li> */}
        </div>
      </div>
    </div>
  );
}

export default ProductsHoverBox;
