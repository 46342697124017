import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);

  const [verified, setVerified] = useState(false);

  const recaptchaRef = useRef(null);

  // When ReCAPTCHA is successfully worked, this function is executed
  function onChange(value) {
    setVerified(true);
  }

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          { name, email, subject, message },
          process.env.REACT_APP_USER_ID
        )
        .then(
          (response) => {
            // console.log("SUCCESS!", response.status, response.text);
            setSendSuccess(true);
            setVerified(false);
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
            recaptchaRef.current.reset();
          },
          (err) => {
            // console.log("FAILED...", err.text);
            setSendError(err.text);
            setVerified(false);
            recaptchaRef.current.reset();
          }
        );
    } catch (err) {
      setSendError(err);
      setVerified(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <div>
      <div className="shadow-sm">
        {sendSuccess ? (
          <div className="alert alert-success mb-4" role="alert">
            Email was successfully sended!
          </div>
        ) : sendError ? (
          <div className="alert alert-danger mb-4" role="alert">
            An error ocurred with message : {sendError}
          </div>
        ) : (
          props.note === true && (
            <div className="alert alert-warning shadow-sm mb-4" role="alert">
              <h4 className="alert-heading mb-3">Note</h4>
              <p className="mb-2">
                All fields require input values. Then if you press{" "}
                <strong>Recapture</strong> and pass authentication, the{" "}
                <strong>'Send an Email'</strong> button will be activated.
              </p>
              {/* <hr />
              <p className="mb-0">
                Whenever you need to, be sure to use margin utilities to keep
                things nice and tidy.
              </p> */}
            </div>
          )
        )}
      </div>
      <form className="contact-form" onSubmit={submitHandler}>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <input
              type="text"
              placeholder="Your Name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="form-control form-control-lg fs-6 shadow-sm border-0"
            />
          </div>
          <div className="col-lg-6 mb-4">
            <input
              type="email"
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-control form-control-lg fs-6
              shadow-sm border-0"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <input
              type="text"
              placeholder="Subject"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              className="form-control form-control-lg fs-6
              shadow-sm border-0"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <textarea
              rows="5"
              placeholder="Message..."
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="form-control form-control-lg fs-6
              shadow-sm border-0"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <ReCAPTCHA
              className="mb-4"
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={recaptchaRef}
              onChange={onChange}
              onExpired={() => setVerified(false)}
            />
            <button
              type="submit"
              className={`btn btn-outline-dark px-3 ${!verified && "disabled"}`}
            >
              Send an Email
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
